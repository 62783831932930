export function fetchUsers(state, { page, sort, filter }) {
  return {
    ...state,
    users: {
      ...state.users,
      currentPage: page,
      sort: sort || state.users.sort,
      filter: filter || state.users.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchUsersSuccess(state, users) {
  return {
    ...state,
    users: {
      ...state.users,
      numberOfPages: users.numberOfPages,
      count: users.count,
      items: users.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchUsersFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    users: {
      ...state.users,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function fetchUserDetails(state, payload) {
  return {
    user: {
      ...state.user,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchUserDetailsSuccess(state, user) {
  let users = state.users;
  if (!users || users.length === 0) {
    users = {
      items: [user.user],
      count: 1,
      page: 1,
    };
  }
  return {
    ...state,
    users,
    user: {
      userDetails: user.user,
      questionsDetails: user.questionsDetails,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchUserDetailsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    user: {
      ...state.user,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function fetchUserWithTargets(state, { page }) {
  return {
    ...state,
    user: {
      ...state.user,
      currentPage: page,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchUserWithTargetsSuccess(state, user) {
  let users = state.users;
  if (!users || users.length === 0) {
    users = {
      items: [user.user],
      count: 1,
      page: 1,
    };
  }
  return {
    ...state,
    users,
    user: {
      ...state.user,
      userDetails: user.user,
      questionsDetails: user.questionsDetails,
      surveys: user.surveys,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchUserWithTargetsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    user: {
      ...state.user,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function fetchUsersWithClickedGroups(state, { page, filter }) {
  return {
    ...state,
    usersWithClickedGroups: {
      ...state.usersWithClickedGroups,
      currentPage: page,
      filter: filter || state.usersWithClickedGroups.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchUsersWithClickedGroupsSuccess(state, users) {
  return {
    ...state,
    usersWithClickedGroups: {
      ...state.usersWithClickedGroups,
      numberOfPages: users.numberOfPages,
      count: users.count,
      items: users.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchUsersWithClickedGroupsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    usersWithClickedGroups: {
      ...state.usersWithClickedGroups,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function fetchUsersWithReversals(state, { page, sort, filter }) {
  return {
    ...state,
    usersWithReversals: {
      ...state.usersWithReversals,
      currentPage: page,
      sort: sort || state.usersWithReversals.sort,
      filter: filter || state.usersWithReversals.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchUsersWithReversalsSuccess(state, users) {
  return {
    ...state,
    usersWithReversals: {
      ...state.usersWithReversals,
      numberOfPages: users.numberOfPages,
      count: users.count,
      items: users.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchUsersWithReversalsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    usersWithReversals: {
      ...state.usersWithReversals,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}
