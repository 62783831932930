import { createAction } from 'redux-act';

import * as users from '../../core/users';

export const fetchUsers = createAction(
  'get all users',
);
export const fetchUsersSuccess = createAction(
  'get all users success',
  (allUsers) => allUsers
);
export const fetchUsersFail = createAction(
  'get all users fail',
  (error) => error
);

export const fetchUserDetails = createAction(
  'get user details',
);
export const fetchUserDetailsSuccess = createAction(
  'get user details success',
  (user) => user
);
export const fetchUserDetailsFail = createAction(
  'get user details fail',
  (error) => error
);

export const fetchUserWithTargets = createAction(
  'get user with targets',
);
export const fetchUserWithTargetsSuccess = createAction(
  'get user with targets success',
  (user) => user
);
export const fetchUserWithTargetsFail = createAction(
  'get user with targets fail',
  (error) => error
);

export const fetchUsersWithClickedGroups = createAction(
  'get all users with clicked groups',
);
export const fetchUsersWithClickedGroupsSuccess = createAction(
  'get all users with clicked groups success',
  (allUsers) => allUsers
);
export const fetchUsersWithClickedGroupsFail = createAction(
  'get all users with clicked groups fail',
  (error) => error
);

export const fetchUsersWithReversals = createAction(
  'get all users with reversals',
);
export const fetchUsersWithReversalsSuccess = createAction(
  'get all users with reversals success',
  (allUsers) => allUsers
);
export const fetchUsersWithReversalsFail = createAction(
  'get all users with reversals fail',
  (error) => error
);

export const reducer = {
  [fetchUsers]: (state, payload) =>
    users.fetchUsers(state, payload),
  [fetchUsersSuccess]: (state, payload) =>
    users.fetchUsersSuccess(state, payload),
  [fetchUsersFail]: (state, payload) =>
    users.fetchUsersFail(state, payload),

  [fetchUserDetails]: (state, payload) =>
    users.fetchUserDetails(state, payload),
  [fetchUserDetailsSuccess]: (state, payload) =>
    users.fetchUserDetailsSuccess(state, payload),
  [fetchUserDetailsFail]: (state, payload) =>
    users.fetchUserDetailsFail(state, payload),

  [fetchUserWithTargets]: (state, payload) =>
    users.fetchUserWithTargets(state, payload),
  [fetchUserWithTargetsSuccess]: (state, payload) =>
    users.fetchUserWithTargetsSuccess(state, payload),
  [fetchUserWithTargetsFail]: (state, payload) =>
    users.fetchUserWithTargetsFail(state, payload),

  [fetchUsersWithClickedGroups]: (state, payload) =>
    users.fetchUsersWithClickedGroups(state, payload),
  [fetchUsersWithClickedGroupsSuccess]: (state, payload) =>
    users.fetchUsersWithClickedGroupsSuccess(state, payload),
  [fetchUsersWithClickedGroupsFail]: (state, payload) =>
    users.fetchUsersWithClickedGroupsFail(state, payload),

  [fetchUsersWithReversals]: (state, payload) =>
    users.fetchUsersWithReversals(state, payload),
  [fetchUsersWithReversalsSuccess]: (state, payload) =>
    users.fetchUsersWithReversalsSuccess(state, payload),
  [fetchUsersWithReversalsFail]: (state, payload) =>
    users.fetchUsersWithReversalsFail(state, payload),
};
