import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as reconciliationsLogsModule from '../modules/reconciliationsLogs.module';
import * as Api from '../../core/api';
import { getSort, getFilter } from './selectors/reconciliationsLogs';

function* fetchReconciliationsLogs({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);

  const { response, error } =
    yield call(Api.reconciliationsLogs.fetchReconciliationsLogs, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(reconciliationsLogsModule.fetchReconciliationsLogsSuccess(response));
  } else {
    yield put(reconciliationsLogsModule.fetchReconciliationsLogsFail(error));
  }
}

export default [
  takeEvery(reconciliationsLogsModule.fetchReconciliationsLogs.getType(), fetchReconciliationsLogs),
];
