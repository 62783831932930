import { apiRequest, postApiRequest, putApiRequest } from './_tools';

export function fetchGlobalSettings() {
  return apiRequest('/api/globalSettings')
    .catch(({ error }) => Promise.reject(error));
}

export function changeProviderStatus({ provider, status, activeCountries }) {
  return postApiRequest('/api/globalSettings/changeProviderStatus/', { provider, status, activeCountries })
    .catch(({ error }) => Promise.reject(error));
}

export function changeDefaultActiveRankTypes({ defaultActiveRankTypes }) {
  return putApiRequest('/api/globalSettings/defaultActiveRankTypes/', { defaultActiveRankTypes })
    .catch(({ error }) => Promise.reject(error));
}

export function updateFeatureFlags(featureFlags) {
  return postApiRequest('/api/globalSettings/changeFeatureFlags/', featureFlags)
    .catch(({ error }) => Promise.reject(error));
}
