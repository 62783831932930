export function fetchClicks(state, { page, sort, filter }) {
  return {
    ...state,
    clicks: {
      ...state.clicks,
      currentPage: page,
      sort: sort || state.clicks.sort,
      filter: filter || state.clicks.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchClicksSuccess(state, clicks) {
  return {
    ...state,
    clicks: {
      ...state.clicks,
      numberOfPages: clicks.numberOfPages,
      count: clicks.count,
      items: clicks.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchClicksFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    clicks: {
      ...state.clicks,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}
