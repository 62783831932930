import { apiRequest } from './_tools';

export function fetchAllCountries({ filter, sort }) {
  const surveyProvider = encodeURIComponent(filter.surveyProvider);

  return apiRequest('/api/reports/desktopMobileSurveysAllCountries?' +
    `dateFrom=${filter.date.from}` +
    `&dateTo=${filter.date.to}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}` +
    `&countryCode=${filter.countryCode}` +
    `&surveyProvider=${surveyProvider}`)
    .catch(({ error }) => Promise.reject(error));
}
