export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  filter: {
    _id: '',
    userId: '',
    surveyGroupId: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  filter: {
    _id: '',
    userId: '',
    surveyGroupId: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};
