import { createAction } from 'redux-act';

import * as mappings from '../../core/mappings';

export const fetchMappings = createAction(
  'get all mappings',
);
export const fetchMappingsSuccess = createAction(
  'get all mappings success',
  (allMappings) => allMappings
);
export const fetchMappingsFail = createAction(
  'get all mappings fail',
  (error) => error
);

export const fetchMappingsDetails = createAction(
  'get mappings',
);
export const fetchMappingsDetailsSuccess = createAction(
  'get mappings success',
  (allMappings) => allMappings
);
export const fetchMappingsDetailsFail = createAction(
  'get mappings fail',
  (error) => error
);

export const fetchQuestions = createAction(
  'get questions',
);
export const fetchQuestionsSuccess = createAction(
  'get questions success',
  (allMappings) => allMappings
);
export const fetchQuestionsFail = createAction(
  'get questions fail',
  (error) => error
);

export const saveMappings = createAction(
  'save mapping',
);
export const saveMappingsSuccess = createAction(
  'save mapping success',
);
export const saveMappingsFail = createAction(
  'save mapping fail',
  (error) => error
);

export const addAnotherQuestion = createAction(
  'add another question',
);
export const addAnotherQuestionSuccess = createAction(
  'add another question success',
);
export const addAnotherQuestionFail = createAction(
  'add another question fail',
  (error) => error
);


export const removeQuestionMapping = createAction(
  'remove question mapping',
);
export const removeQuestionMappingSuccess = createAction(
  'remove question mapping success',
);
export const removeQuestionMappingFail = createAction(
  'remove question mapping fail',
  (error) => error
);

export const changeMappingStatus = createAction(
  'change mapping status',
);
export const changeMappingStatusSuccess = createAction(
  'change mapping status success',
);
export const changeMappingStatusFail = createAction(
  'change mapping status fail',
  (error) => error
);

export const changeQuestionText = createAction(
  'change question text',
);
export const changeQuestionTextSuccess = createAction(
  'change question text success',
);
export const changeQuestionTextFail = createAction(
  'change question text fail',
  (error) => error
);

export const openMappingsDetailsModal = createAction(
  'open mappings details confirmation modal'
);

export const closeMappingsDetailsModal = createAction(
  'close mapping details confirmation modal'
);

export const reducer = {
  [fetchMappings]: (state, payload) =>
    mappings.fetchMappings(state, payload),
  [fetchMappingsSuccess]: (state, payload) =>
    mappings.fetchMappingsSuccess(state, payload),
  [fetchMappingsFail]: (state, payload) =>
    mappings.fetchMappingsFail(state, payload),

  [saveMappings]: (state, payload) =>
    mappings.saveMappings(state, payload),
  [saveMappingsSuccess]: (state, payload) =>
    mappings.saveMappingsSuccess(state, payload),
  [saveMappingsFail]: (state, payload) =>
    mappings.saveMappingsFail(state, payload),


  [addAnotherQuestion]: (state, payload) =>
    mappings.addAnotherQuestion(state, payload),
  [addAnotherQuestionSuccess]: (state, payload) =>
    mappings.addAnotherQuestionSuccess(state, payload),
  [addAnotherQuestionFail]: (state, payload) =>
    mappings.addAnotherQuestionFail(state, payload),

  [removeQuestionMapping]: (state, payload) =>
    mappings.removeQuestionMapping(state, payload),
  [removeQuestionMappingSuccess]: (state, payload) =>
    mappings.removeMappingSuccess(state, payload),
  [removeQuestionMappingFail]: (state, payload) =>
    mappings.removeMappingFail(state, payload),

  [changeMappingStatus]: (state, payload) =>
    mappings.changeMappingStatus(state, payload),
  [changeMappingStatusSuccess]: (state, payload) =>
    mappings.changeMappingStatusSuccess(state, payload),
  [changeMappingStatusFail]: (state, payload) =>
    mappings.changeMappingStatusFail(state, payload),


  [fetchMappingsDetailsSuccess]: (state, payload) =>
    mappings.fetchMappingsDetailsSuccess(state, payload),
  [fetchMappingsDetails]: (state, payload) =>
    mappings.fetchMappingsDetails(state, payload),

  [fetchQuestions]: (state, payload) =>
    mappings.fetchQuestions(state, payload),
  [fetchQuestionsSuccess]: (state, payload) =>
    mappings.fetchQuestionsSuccess(state, payload),
  [fetchQuestionsFail]: (state, payload) =>
    mappings.fetchQuestionsFail(state, payload),

  [changeQuestionText]: (state, payload) =>
    mappings.changeQuestionText(state, payload),
  [changeQuestionTextSuccess]: (state, payload) =>
    mappings.changeQuestionTextSuccess(state, payload),
  [changeQuestionTextFail]: (state, payload) =>
    mappings.changeQuestionTextFail(state, payload),

  [openMappingsDetailsModal]: (state, payload) =>
    mappings.openMappingsDetailsModal(state, payload),
  [closeMappingsDetailsModal]: (state, payload) =>
    mappings.closeMappingsDetailsModal(state, payload),
};
