import { apiRequest } from './_tools';

export function fetchSaveLeadLogs({ page, itemsPerPage, sort, filter }) {
  return apiRequest(`/api/saveLeadLogs?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&userId=${filter.userId}` +
    `&clickId=${filter.clickId}` +
    `&surveyProvider=${filter.surveyProvider}` +
    `&type=${filter.type}` +
    `&leadStatus=${filter.leadStatus}` +
    `&dateFrom=${filter.date.from}` +
    `&dateTo=${filter.date.to}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
  .catch(({ error }) => Promise.reject(error));
}
