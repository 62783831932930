import { createAction } from 'redux-act';

import * as rankAdjustments from '../../core/rankAdjustments';

export const fetchRankAdjustments = createAction(
  'get all rankAdjustments',
);
export const fetchRankAdjustmentsSuccess = createAction(
  'get all rankAdjustments success',
  (allRankAdjustments) => allRankAdjustments
);
export const fetchRankAdjustmentsFail = createAction(
  'get all rankAdjustments fail',
  (error) => error
);

export const openUpdateRankAdjustment = createAction(
  'open update rankAdjustment modal'
);

export const closeUpdateRankAdjustment = createAction(
  'close update rankAdjustment modal'
);

export const updateRankAdjustment = createAction(
  'update rankAdjustment'
);
export const updateRankAdjustmentSuccess = createAction(
  'update rankAdjustment success'
);
export const updateRankAdjustmentFail = createAction(
  'update rankAdjustment fail',
  (error) => error
);

export const deleteRankAdjustment = createAction(
  'delete rankAdjustment'
);
export const deleteRankAdjustmentSuccess = createAction(
  'delete rankAdjustment success'
);
export const deleteRankAdjustmentFail = createAction(
  'delete rankAdjustment fail',
  (error) => error
);

export const addRankAdjustment = createAction(
  'add new rankAdjustment',
);
export const addRankAdjustmentSuccess = createAction(
  'add new rankAdjustment success',
  (rankAdjustment) => rankAdjustment
);
export const addRankAdjustmentFail = createAction(
  'add new rankAdjustment fail',
  (error) => error
);

export const reducer = {
  [fetchRankAdjustments]: (state, payload) =>
    rankAdjustments.fetchRankAdjustments(state, payload),
  [fetchRankAdjustmentsSuccess]: (state, payload) =>
    rankAdjustments.fetchRankAdjustmentsSuccess(state, payload),

  [addRankAdjustmentSuccess]: (state, payload) =>
    rankAdjustments.addRankAdjustmentSuccess(state, payload),

  [openUpdateRankAdjustment]: (state, payload) =>
    rankAdjustments.openUpdateRankAdjustment(state, payload),
  [closeUpdateRankAdjustment]: (state, payload) =>
    rankAdjustments.closeUpdateRankAdjustment(state, payload),
  [updateRankAdjustmentSuccess]: (state, payload) =>
    rankAdjustments.updateRankAdjustmentSuccess(state, payload),
  [deleteRankAdjustmentSuccess]: (state, payload) =>
    rankAdjustments.deleteRankAdjustmentSuccess(state, payload),
};
