import { createAction } from 'redux-act';

import * as saveLeadLogs from '../../core/saveLeadLogs';

export const fetchSaveLeadLogs = createAction(
  'get all save lead logs',
);
export const fetchSaveLeadLogsSuccess = createAction(
  'get all save lead logs success',
  (allLogs) => allLogs
);
export const fetchSaveLeadLogsFail = createAction(
  'get all save lead logs fail',
  (error) => error
);

export const reducer = {
  [fetchSaveLeadLogs]: (state, payload) =>
    saveLeadLogs.fetchSaveLeadLogs(state, payload),
  [fetchSaveLeadLogsSuccess]: (state, payload) =>
    saveLeadLogs.fetchSaveLeadLogsSuccess(state, payload),
  [fetchSaveLeadLogsFail]: (state, payload) =>
    saveLeadLogs.fetchSaveLeadLogsFail(state, payload),
};
