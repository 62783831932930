import { apiRequest } from './_tools';

export function fetchSurveyQualificationsLogs({ page, itemsPerPage, sort, filter }) {
  return apiRequest(`/api/clicksByPages/withSurveyQualifications?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&internalUserId=${filter.internalUserId}` +
    `&id=${filter.id}` +
    `&clickId=${filter.clickId}` +
    `&userId=${filter.userId}` +
    `&userCountryCode=${filter.userCountryCode}` +
    `&surveyId=${filter.surveyId}` +
    `&surveyProvider=${filter.surveyProvider}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
    .catch(({ error }) => Promise.reject(error));
}
