import { takeEvery, put, call, select } from 'redux-saga/effects';
import { reset as resetForm } from 'redux-form';

import * as allocationsModule from '../modules/allocations.module';
import * as Api from '../../core/api';

import { getSort, getFilter } from './selectors/allocations';

function* fetchAllocations({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);

  const { response, error } = yield call(Api.allocations.fetchAllocations, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(allocationsModule.fetchAllocationsSuccess(response));
  } else {
    yield put(allocationsModule.fetchAllocationsFail(error));
  }
}

function* addAllocation({ payload: allocation }) {
  const { response, error } = yield call(Api.allocations.addAllocation, allocation);

  if (response) {
    yield put(allocationsModule.addAllocationSuccess(response));
    yield put(allocationsModule.closeUpdateAllocation());
    yield put(resetForm('addAllocation'));
  } else {
    yield put(allocationsModule.addAllocationFail(error));
  }
}

function* updateAllocation({ payload: { allocationId, allocation } }) {
  const { response, error } = yield call(Api.allocations.updateAllocation, { allocationId, allocation });

  if (response) {
    yield put(allocationsModule.updateAllocationSuccess({ allocationId, allocation }));
    yield put(allocationsModule.closeUpdateAllocation());
    yield put(resetForm('addAllocation'));
  } else {
    yield put(allocationsModule.updateAllocationFail(error));
  }
}

function* deleteAllocation({ payload: { allocationId } }) {
  const { response, error } = yield call(Api.allocations.deleteAllocation, { allocationId });

  if (response) {
    yield put(allocationsModule.deleteAllocationSuccess({ allocationId }));
  } else {
    yield put(allocationsModule.deleteAllocationFail(error));
  }
}

export default [
  takeEvery(allocationsModule.fetchAllocations.getType(), fetchAllocations),
  takeEvery(allocationsModule.addAllocation.getType(), addAllocation),
  takeEvery(allocationsModule.updateAllocation.getType(), updateAllocation),
  takeEvery(allocationsModule.deleteAllocation.getType(), deleteAllocation),
];
