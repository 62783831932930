import { takeEvery, put, call, select } from 'redux-saga/effects';
import { reset as resetForm } from 'redux-form';

import * as appsModule from '../modules/apps.module';
import * as Api from '../../core/api';

import { getSort, getFilter } from './selectors/apps';

function* fetchApps({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);

  const { response, error } = yield call(Api.apps.fetchApps, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(appsModule.fetchAppsSuccess(response));
  } else {
    yield put(appsModule.fetchAppsFail(error));
  }
}

function* addApp({ payload: app }) {
  const { response, error } = yield call(Api.apps.addApp, app);

  if (response) {
    yield put(appsModule.addAppSuccess(response));
    yield put(appsModule.closeUpdateApp());
    yield put(resetForm('addApp'));
  } else {
    yield put(appsModule.addAppFail(error));
  }
}

function* updateApp({ payload: { appId, app } }) {
  const { response, error } = yield call(Api.apps.updateApp, { appId, app });

  if (response) {
    yield put(appsModule.updateAppSuccess({ appId, app }));
    yield put(appsModule.closeUpdateApp());
    yield put(resetForm('addApp'));
  } else {
    yield put(appsModule.updateAppFail(error));
  }
}

export default [
  takeEvery(appsModule.fetchApps.getType(), fetchApps),
  takeEvery(appsModule.addApp.getType(), addApp),
  takeEvery(appsModule.updateApp.getType(), updateApp),
];

