import { apiRequest, putApiRequest } from './_tools';

export function fetchPayments({ page, itemsPerPage, sort, filter }) {
  const [year, month] = filter.period.split('/');
  return apiRequest(`/api/payments?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&provider=${filter.provider}` +
    `&month=${month}` +
    `&year=${year}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
  .catch(({ error }) => Promise.reject(error));
}

export function changeProviderPayment({ paymentId, paymentValue }) {
  return putApiRequest(`/api/payments/${paymentId}/paymentValue`, { paymentValue });
}
