import { createAction } from 'redux-act';

import * as managers from '../../core/managers';

export const fetchManagers = createAction(
  'get all managers',
);
export const fetchManagersSuccess = createAction(
  'get all managers success',
  (allManagers) => allManagers
);
export const fetchManagersFail = createAction(
  'get all managers fail',
  (error) => error
);

export const openAddManagerModal = createAction(
  'open add manager modal',
);
export const closeAddManagerModal = createAction(
  'close add manager modal',
);

export const addManager = createAction(
  'add manager',
);
export const addManagerSuccess = createAction(
  'add manager success',
);
export const addManagerFail = createAction(
  'add manager fail',
  (error) => error
);

export const reducer = {
  [fetchManagers]: (state, payload) =>
    managers.fetchManagers(state, payload),
  [fetchManagersSuccess]: (state, payload) =>
    managers.fetchManagersSuccess(state, payload),

  [openAddManagerModal]: (state, payload) =>
    managers.openAddManagerModal(state, payload),
  [closeAddManagerModal]: (state, payload) =>
    managers.closeAddManagerModal(state, payload),

  [addManagerSuccess]: (state, payload) =>
    managers.addManagerSuccess(state, payload),
  [addManagerFail]: (state, payload) =>
    managers.addManagerFail(state, payload),
};
