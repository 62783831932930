import React  from 'react';
import PropTypes from 'prop-types';

export default function InputError({ touched, error, message, meta }) {
  const isTouched = meta ? meta.touched : touched;
  const errorMessage = meta ? meta.error : message || error;

  const shownMessage = (typeof isTouched === 'undefined' || isTouched) && errorMessage;
  const isShown = !!shownMessage;

  if (!isShown) {
    return null;
  }

  return (
    <small className="error">
      {errorMessage}
    </small>
  );
}

const errorPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
})]);

InputError.propTypes = {
  touched: PropTypes.bool,
  message: PropTypes.string,
  error: errorPropType,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: errorPropType,
  }),
};
