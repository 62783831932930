export const test = {
  isFetching: false,
  fetchingErrorMessage: '',
  confirmationModal: {
    showModal: false,
    actionName: '',
    payload: {},
  },
};

export const initial = {
  isFetching: false,
  fetchingErrorMessage: '',
  confirmationModal: {
    showModal: false,
    actionName: '',
    payload: {},
  },
};
