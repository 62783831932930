import { createAction } from 'redux-act';

import * as feasibility from '../../core/feasibility';

export const fetchFeasibility = createAction(
  'get all feasibility',
);
export const fetchFeasibilitySuccess = createAction(
  'get all feasibility success',
  (allUsers) => allUsers
);
export const fetchFeasibilityFail = createAction(
  'get all feasibility fail',
  (error) => error
);

export const updateFeasibilityConstants = createAction(
  'update feasibility constants'
);
export const updateFeasibilityConstantsSuccess = createAction(
  'update feasibility constants success'
);
export const updateFeasibilityConstantsFail = createAction(
  'update feasibility constants fail'
);

export const changeConstantsUpdateStatus = createAction(
  'change feasibility constants update status'
);

export const reducer = {
  [fetchFeasibility]: (state, payload) =>
    feasibility.fetchFeasibility(state, payload),
  [fetchFeasibilitySuccess]: (state, payload) =>
    feasibility.fetchFeasibilitySuccess(state, payload),
  [fetchFeasibilityFail]: (state, payload) =>
    feasibility.fetchFeasibilityFail(state, payload),

  [changeConstantsUpdateStatus]: (state, payload) =>
    feasibility.changeConstantsUpdateStatus(state, payload),

  [updateFeasibilityConstantsSuccess]: (state, payload) =>
    feasibility.updateFeasibilityConstantsSuccess(state, payload),
  [updateFeasibilityConstantsFail]: (state, payload) =>
    feasibility.updateFeasibilityConstantsFail(state, payload),
};
