import { takeEvery, put, call, fork, select } from 'redux-saga/effects';

import * as questionsModule from '../modules/questions.module';
import * as Api from '../../core/api';
import { getSort, getFilter } from './selectors/questions';

function* fetchQuestions({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);
  const { response, error } =
    yield call(Api.questions.fetchQuestions, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(questionsModule.fetchQuestionsSuccess(response));
  } else {
    yield put(questionsModule.fetchQuestionsFail(error));
  }
}

function* fetchQuestionsByCountryLanguageCode({ payload: { countryLanguageCode } }) {
  if (!countryLanguageCode) {
    yield put(questionsModule.fetchQuestionsByCountryLanguageCodeSuccess([]));
  } else {
    const { response, error } =
      yield call(Api.questions.fetchQuestionsByCountryLanguageCode, countryLanguageCode);

    if (response) {
      yield put(questionsModule.fetchQuestionsByCountryLanguageCodeSuccess(response));
    } else {
      yield put(questionsModule.fetchQuestionsByCountryLanguageCodeFail(error));
    }
  }
}

function* updateQuestion({ payload: { questionId, redHerringAnswers } }) {
  const { response, error } =
    yield call(Api.questions.updateQuestion, { questionId, redHerringAnswers });

  if (response) {
    yield put(questionsModule.updateQuestionSuccess({
      questionId, redHerringAnswers,
    }));
    yield put(questionsModule.closeEditQuestionModal());
  } else {
    yield put(questionsModule.updateQuestionFail(error));
  }
}

export default [
  takeEvery(questionsModule.fetchQuestions.getType(), fetchQuestions),
  takeEvery(questionsModule.fetchQuestionsByCountryLanguageCode.getType(), fetchQuestionsByCountryLanguageCode),
  takeEvery(questionsModule.updateQuestion.getType(), updateQuestion),
];

