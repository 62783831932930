import { takeEvery, put, call } from 'redux-saga/effects';

import * as countryLanguagesModule from '../modules/countryLanguages.module';
import * as Api from '../../core/api';

function* fetchAllCountryLanguages({ payload: { } }) {
  const { response, error } = yield call(Api.countryLanguages.fetchAllCountryLanguages, {});

  if (response) {
    yield put(countryLanguagesModule.fetchAllCountryLanguagesSuccess(response));
  } else {
    yield put(countryLanguagesModule.fetchAllCountryLanguagesFail(error));
  }
}

export default [
  takeEvery(countryLanguagesModule.fetchAllCountryLanguages.getType(), fetchAllCountryLanguages),
];
