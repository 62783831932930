export function fetchApps(state, { page, sort, filter }) {
  return {
    ...state,
    apps: {
      ...state.apps,
      currentPage: page,
      sort: sort || state.apps.sort,
      filter: filter || state.apps.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchAppsSuccess(state, apps) {
  return {
    ...state,
    apps: {
      ...state.apps,
      numberOfPages: apps.numberOfPages,
      count: apps.count,
      items: apps.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchAppsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    apps: {
      ...state.apps,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function addAppSuccess(state, app) {
  return {
    ...state,
    apps: {
      ...state.apps,
      items: [
        ...state.apps.items,
        app,
      ],
    },
  };
}

export function openUpdateApp(state, { app }) {
  return {
    ...state,
    editApp: {
      showModal: true,
      app,
    },
  };
}

export function closeUpdateApp(state) {
  return {
    ...state,
    editApp: {
      showModal: false,
    },
  };
}

export function updateAppSuccess(state, { appId, app: appData }) {
  const appIndex = state.apps.items.findIndex(x => x._id === appId);
  const app = state.apps.items.find(x => x._id === appId);
  if (!app) {
    return state;
  }
  return {
    ...state,
    apps: {
      ...state.apps,
      items: [
        ...state.apps.items.slice(0, appIndex),
        {
          ...app,
          userEmail: appData.userEmail,
          name: appData.name,
          type: appData.type,
          currencyName: appData.currencyName,
          exchangeRate: appData.exchangeRate,
        },
        ...state.apps.items.slice(appIndex + 1),
      ],
    },
  };
}
