import moment from 'moment-timezone';

const state = {
  filter: {
    date: {
      from: moment.tz('America/Los_Angeles').subtract(7, 'day').startOf('day').format('MM/DD/YYYY'),
      to: moment.tz('America/Los_Angeles').startOf('day').format('MM/DD/YYYY'),
    },
    surveyProvider: '',
    countryCode: '',
  },
  allCountries: {
    items: [],
    sort: {
      key: 'clicksCount',
      order: -1,
    },
  },
  isFetching: false,
  fetchingErrorMessages: [],
  activeFetchingCounter: 0,
};

export const test = state;

export const initial = state;
