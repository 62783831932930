import _ from 'lodash';
import { createReducer } from 'redux-act';

import * as states from './state/index.state.js';

import * as modules from './modules';

const reducers = _.reduce(modules, (result, value, key) => {
  return {
    ...result,
    ...value.reducer,
  };
}, {});

export default createReducer(
  reducers,
  states.initial,
);
