import { createAction } from 'redux-act';

import * as globalSettings from '../../core/globalSettings';


export const fetchGlobalSettings = createAction(
  'get all global settings',
);
export const fetchGlobalSettingsSuccess = createAction(
  'get all global settings success',
  (allGlobalSettings) => allGlobalSettings
);
export const fetchGlobalSettingsFail = createAction(
  'get all global settings fail',
  (error) => error
);

export const changeProviderStatus = createAction(
  'change provider status',
);
export const changeProviderStatusSuccess = createAction(
  'change provider status success',
);
export const changeProviderStatusFail = createAction(
  'change provider status fail',
  (error) => error
);

export const changeDefaultActiveRankTypes = createAction(
  'change default active rank types',
);
export const changeDefaultActiveRankTypesSuccess = createAction(
  'change default active rank types success',
);
export const changeDefaultActiveRankTypesFail = createAction(
  'change default active rank types fail',
  (error) => error
);

export const changeFeatureStatus = createAction('change feature status');
export const changeFeatureStatusSuccess = createAction('change feature status success');
export const changeFeatureStatusFail = createAction('change feature status fail', (error) => error);


export const openConfirmationModal = createAction(
  'open settings confirmation modal'
);

export const closeConfirmationModal = createAction(
  'close settings confirmation modal'
);

export const reducer = {
  [fetchGlobalSettings]: (state, payload) =>
    globalSettings.fetchGlobalSettings(state, payload),
  [fetchGlobalSettingsSuccess]: (state, payload) =>
    globalSettings.fetchGlobalSettingsSuccess(state, payload),
  [fetchGlobalSettingsFail]: (state, payload) =>
    globalSettings.fetchGlobalSettingsFail(state, payload),

  [changeProviderStatus]: (state, payload) =>
    globalSettings.changeProviderStatus(state, payload),
  [changeProviderStatusSuccess]: (state, payload) =>
    globalSettings.changeProviderStatusSuccess(state, payload),
  [changeProviderStatusFail]: (state, payload) =>
    globalSettings.changeProviderStatusFail(state, payload),

  [changeFeatureStatus]: (state, payload) => globalSettings.changeFeatureStatus(state, payload),
  [changeFeatureStatusSuccess]: (state, payload) => globalSettings.changeFeatureStatusSuccess(state, payload),
  [changeFeatureStatusFail]: (state, payload) => globalSettings.changeFeatureStatusFail(state, payload),

  [changeDefaultActiveRankTypes]: (state, payload) =>
    globalSettings.changeDefaultActiveRankTypes(state, payload),
  [changeDefaultActiveRankTypesSuccess]: (state, payload) =>
    globalSettings.changeDefaultActiveRankTypesSuccess(state, payload),
  [changeDefaultActiveRankTypesFail]: (state, payload) =>
    globalSettings.changeDefaultActiveRankTypesFail(state, payload),

  [openConfirmationModal]: (state, payload) =>
    globalSettings.openConfirmationModal(state, payload),
  [closeConfirmationModal]: (state, payload) =>
    globalSettings.closeConfirmationModal(state, payload),
};
