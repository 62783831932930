import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as saveLeadLogsModule from '../modules/saveLeadLogs.module';
import * as Api from '../../core/api';
import { getSort, getFilter } from './selectors/saveLeadLogs';

function* fetchSaveLeadLogs({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);

  const { response, error } =
    yield call(Api.saveLeadLogs.fetchSaveLeadLogs, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(saveLeadLogsModule.fetchSaveLeadLogsSuccess(response));
  } else {
    yield put(saveLeadLogsModule.fetchSaveLeadLogsFail(error));
  }
}

export default [
  takeEvery(saveLeadLogsModule.fetchSaveLeadLogs.getType(), fetchSaveLeadLogs),
];

