import { apiRequest, postApiRequest, deleteApiRequest } from './_tools';

export function fetchMappings({ provider, page, itemsPerPage, filter }) {
  const questionName = encodeURIComponent(filter.questionName);
  return apiRequest(`/api/mappings/${provider}?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&country=${filter.country}` +
    `&questionName=${questionName}`)
    .catch(({ error }) => Promise.reject(error));
}

export function fetchMappingsDetails({ provider, country, questionId, index }) {
  return apiRequest(`/api/mappings/details/${provider}/${questionId}/${country}/${index || 0}`)
    .catch(({ error }) => Promise.reject(error));
}

export function fetchQuestions({ countryLanguageId }) {
  return apiRequest(`/api/mappings/questions/${countryLanguageId}`)
    .catch(({ error }) => Promise.reject(error));
}

export function saveMappings({ provider, country, questionId, index, mappings }) {
  return postApiRequest(`/api/mappings/${provider}/${questionId}/${country}/${index}`, mappings)
    .catch(({ error }) => Promise.reject(error));
}

export function addAnotherQuestion({ provider, country, questionId }) {
  return apiRequest(`/api/mappings/add/${provider}/${questionId}/${country}`)
    .catch(({ error }) => Promise.reject(error));
}

export function removeQuestionMapping({ questionId, provider, country, index }) {
  return deleteApiRequest(`/api/mappings/${provider}/${questionId}/${country}/${index}`)
    .catch(({ error }) => Promise.reject(error));
}

export function changeMappingStatus({ questionId, provider, country, index }) {
  return postApiRequest(`/api/mappings/changeMappingStatus/${provider}/${questionId}/${country}/${index}`)
    .catch(({ error }) => Promise.reject(error));
}

export function changeQuestionText({ countryLanguageCode, questionId, text }) {
  return postApiRequest(`/api/questions/${questionId}/changeText`, { countryLanguageCode, text })
    .catch(({ error }) => Promise.reject(error));
}

export function fetchUsersWithQuestionAnsweredCount({ countryLanguageId, questionId }) {
  return apiRequest('/api/reports/users/usersWithQuestionAnsweredCount?' +
    `countryLanguageId=${countryLanguageId}` +
    `&questionId=${questionId}`)
    .catch(({ error }) => Promise.reject(error));
}
