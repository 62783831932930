import React from 'react';
import PropTypes from 'prop-types';

export default class Logout extends React.Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.logout();
  }

  render() {
    return (
      <p>You are now logged out</p>
    );
  }
}
