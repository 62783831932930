import { createAction } from 'redux-act';

import * as surveys from '../../core/surveys';

export const fetchSurveys = createAction(
  'get all surveys',
);
export const fetchSurveysSuccess = createAction(
  'get all surveys success',
  (allSurveys) => allSurveys
);
export const fetchSurveysFail = createAction(
  'get all surveys fail',
  (error) => error
);

export const fetchSurveyResponseCodesStats = createAction(
  'get federated survey response codes stats',
);
export const fetchSurveyResponseCodesStatsSuccess = createAction(
  'get federated survey response codes stats success',
  (statsWithSurveyId) => statsWithSurveyId
);
export const fetchSurveyResponseCodesStatsFail = createAction(
  'get federated survey response codes stats fail',
  (error) => error
);

export const changeSurveyPreferredValue = createAction(
  'change survey preferred value',
);
export const changeSurveyPreferredValueSuccess = createAction(
  'change survey preferred value success',
  (statsWithSurveyId) => statsWithSurveyId
);
export const changeSurveyPreferredValueFail = createAction(
  'change survey preferred value fail',
  (error) => error
);

export const openSurveyStatsModal = createAction(
  'open survey stats modal'
);

export const closeSurveyStatsModal = createAction(
  'close survey stats modal'
);

export const reducer = {
  [fetchSurveys]: (state, payload) =>
    surveys.fetchSurveys(state, payload),
  [fetchSurveysSuccess]: (state, payload) =>
    surveys.fetchSurveysSuccess(state, payload),
  [fetchSurveysFail]: (state, payload) =>
    surveys.fetchSurveysFail(state, payload),

  [fetchSurveyResponseCodesStats]: (state, payload) =>
    surveys.fetchSurveyResponseCodesStats(state, payload),
  [fetchSurveyResponseCodesStatsSuccess]: (state, payload) =>
    surveys.fetchSurveyResponseCodesStatsSuccess(state, payload),
  [fetchSurveyResponseCodesStatsFail]: (state, payload) =>
    surveys.fetchSurveyResponseCodesStatsFail(state, payload),

  [changeSurveyPreferredValue]: (state, payload) =>
    surveys.changeSurveyPreferredValue(state, payload),
  [changeSurveyPreferredValueSuccess]: (state, payload) =>
    surveys.changeSurveyPreferredValueSuccess(state, payload),
  [changeSurveyPreferredValueFail]: (state, payload) =>
    surveys.changeSurveyPreferredValueFail(state, payload),

  [openSurveyStatsModal]: (state, payload) =>
    surveys.openSurveyStatsModal(state, payload),
  [closeSurveyStatsModal]: (state, payload) =>
    surveys.closeSurveyStatsModal(state, payload),
};
