import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as desktopMobileSurveysReportsModule from '../modules/desktopMobileSurveysReports.module';
import * as Api from '../../core/api';

import { getFilter, getSort } from './selectors/desktopMobileSurveys';

function* fetchAllCountries() {
  const filter = yield select(getFilter);
  const sort = yield select(getSort);

  const { response, error } = yield call(Api.desktopMobileSurveys.fetchAllCountries, { filter, sort });

  if (response) {
    yield put(desktopMobileSurveysReportsModule.fetchAllCountriesSuccess(response));
  } else {
    yield put(desktopMobileSurveysReportsModule.fetchAllCountriesFail(error));
  }
}

export default [
  takeEvery(desktopMobileSurveysReportsModule.fetchAllCountries.getType(), fetchAllCountries),
];

