import { takeEvery, put, call, fork, select, all } from 'redux-saga/effects';
import { reset as resetForm } from 'redux-form';

import * as managersModule from '../modules/managers.module';
import * as Api from '../../core/api';
import { getSort } from './selectors/managers';

function* fetchManagers({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const { response, error } = yield call(Api.managers.fetchManagers, { page, itemsPerPage, sort });

  if (response) {
    yield put(managersModule.fetchManagersSuccess(response));
  } else {
    yield put(managersModule.fetchManagersFail(error));
  }
}

function* addManager({ payload: manager }) {
  const { response, error } = yield call(Api.managers.addManager, manager);

  if (response) {
    yield put(managersModule.addManagerSuccess(response));
    yield put(managersModule.closeAddManagerModal());
    yield put(resetForm('addManager'));
  } else {
    yield put(managersModule.addManagerFail(error));
  }
}

export default [
  takeEvery(managersModule.fetchManagers.getType(), fetchManagers),
  takeEvery(managersModule.addManager.getType(), addManager),
];

