export function fetchGlobalSettings(state) {
  return {
    ...state,
    globalSettings: {
      ...state.globalSettings,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchGlobalSettingsSuccess(state, settings) {
  return {
    ...state,
    globalSettings: {
      ...state.globalSettings,
      ...settings,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchGlobalSettingsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    globalSettings: {
      ...state.globalSettings,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function changeProviderStatus(state, { provider, status, activeCountries }) {
  return {
    ...state,
    globalSettings: {
      ...state.globalSettings,
      fetchingErrorMessage: '',
      provider,
      status,
      activeCountries,
      isFetching: true,
    },
  };
}

export function changeProviderStatusSuccess(state, { provider, active, activeCountries }) {
  const providerIndex =
    state.globalSettings.providers.findIndex(p => p.name === provider);
  return {
    ...state,
    globalSettings: {
      ...state.globalSettings,
      providers: [
        ...state.globalSettings.providers.slice(0, providerIndex),
        {
          ...state.globalSettings.providers[providerIndex],
          active,
          activeCountries,
          allCountries: activeCountries.length === 0,
        },
        ...state.globalSettings.providers.slice(providerIndex + 1),
      ],
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function changeProviderStatusFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    globalSettings: {
      ...state.globalSettings,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function changeFeatureStatus(state, { feature, status }) {
  return {
    ...state,
    globalSettings: {
      ...state.globalSettings,
      featureFlags: {
        ...state.globalSettings.featureFlags,
        ...{
          [feature]: status,
        },
      },
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function changeFeatureStatusSuccess(state) {
  return {
    ...state,
    globalSettings: {
      ...state.globalSettings,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function changeFeatureStatusFail(state, error) {
  const errorMessage = error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    globalSettings: {
      ...state.globalSettings,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function changeDefaultActiveRankTypes(state, { defaultActiveRankTypes }) {
  return {
    ...state,
    globalSettings: {
      ...state.globalSettings,
      defaultActiveRankTypes,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function changeDefaultActiveRankTypesSuccess(state) {
  return {
    ...state,
    globalSettings: {
      ...state.globalSettings,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function changeDefaultActiveRankTypesFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Invalid rank types';
  return {
    ...state,
    globalSettings: {
      ...state.globalSettings,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function openConfirmationModal(state, { actionName, actionPayload }) {
  return {
    ...state,
    globalSettings: {
      ...state.globalSettings,
      confirmationModal: {
        showModal: true,
        actionName,
        actionPayload,
      },
    },
  };
}

export function closeConfirmationModal(state) {
  return {
    ...state,
    globalSettings: {
      ...state.globalSettings,
      confirmationModal: {
        showModal: false,
        actionName: '',
      },
    },
  };
}
