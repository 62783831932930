export function fetchQuestions(state, { page, sort, filter }) {
  return {
    ...state,
    questions: {
      ...state.questions,
      currentPage: page,
      sort: sort || state.questions.sort,
      filter: filter || state.questions.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchQuestionsSuccess(state, questions) {
  return {
    ...state,
    questions: {
      ...state.questions,
      numberOfPages: questions.numberOfPages,
      count: questions.count,
      items: questions.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchQuestionsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    questions: {
      ...state.questions,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function fetchQuestionsByCountryLanguageCodeSuccess(state, questions) {
  return {
    ...state,
    questions: {
      ...state.questions,
      allQuestionsByCountryLanguageCode: questions,
    },
  };
}

export function openEditQuestionModal(state, { question }) {
  return {
    ...state,
    editQuestion: {
      showModal: true,
      question,
    },
  };
}

export function closeEditQuestionModal(state) {
  return {
    ...state,
    editQuestion: {
      showModal: false,
    },
  };
}

export function updateQuestionSuccess(state, { questionId, redHerringAnswers }) {
  const questionIndex = state.questions.items.findIndex(x => x._id === questionId);
  const question = state.questions.items.find(x => x._id === questionId);
  if (!question) {
    return state;
  }
  return {
    ...state,
    questions: {
      ...state.questions,
      items: [
        ...state.questions.items.slice(0, questionIndex),
        {
          ...question,
          redHerringAnswers: redHerringAnswers.split(','),
          isRedHerring: !!redHerringAnswers,
        },
        ...state.questions.items.slice(questionIndex + 1),
      ],
    },
  };
}

export function updateQuestionFail(state) {
  return state;
}
