export function fetchAllCountryLanguagesSuccess(state, countryLanguages) {
  return {
    ...state,
    countryLanguages: {
      ...state.countryLanguages,
      countryLanguages: countryLanguages.countryLanguages,
      countries: countryLanguages.countries,
    },
  };
}
