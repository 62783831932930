export const test = {
  data: { options: [] },
  countryLanguages: [],
  questions: [],
  fetchingErrorMessage: '',
  areQuestionsFetching: false,
  isFetching: false,
  modal: {
    showModal: false,
    actionPayload: {},
  },
};

export const initial = {
  data: { options: [] },
  countryLanguages: [],
  questions: [],
  fetchingErrorMessage: '',
  areQuestionsFetching: false,
  isFetching: false,
  modal: {
    showModal: false,
    actionPayload: {},
  },
};
