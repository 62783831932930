import { takeEvery, put, call, fork, select } from 'redux-saga/effects';

import * as usersModule from '../modules/users.module';
import * as Api from '../../core/api';
import { getSort, getFilter } from './selectors/users';
import { getFilter as getUsersWithClickedGroupsFilter } from './selectors/usersWithClickedGroups';
import {
  getFilter as getUsersWithReversalsFilter,
  getSort as getUsersWithReversalsSort,
} from './selectors/usersWithReversals';

export function* fetchUsers({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);
  const { response, error } =
    yield call(Api.users.fetchUsers, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(usersModule.fetchUsersSuccess(response));
  } else {
    yield put(usersModule.fetchUsersFail(error));
  }
}

export function* fetchUserDetails({ payload: { userId } }) {
  const { response, error } = yield call(Api.users.fetchUserDetails, userId);

  if (response) {
    yield put(usersModule.fetchUserDetailsSuccess(response));
  } else {
    yield put(usersModule.fetchUserDetailsFail(error));
  }
}

export function* fetchUserWithTargets({ payload: { userId, page, itemsPerPage } }) {
  const { response, error } =
    yield call(Api.users.fetchUserWithTargetedSurveys, { userId, page, itemsPerPage });

  if (response) {
    yield put(usersModule.fetchUserWithTargetsSuccess(response));
  } else {
    yield put(usersModule.fetchUserWithTargetsFail(error));
  }
}

export function* fetchUsersWithClickedGroups({ payload: { page, itemsPerPage } }) {
  const filter = yield select(getUsersWithClickedGroupsFilter);
  const { response, error } =
    yield call(Api.users.fetchUsersWithClickedGroups, { page, itemsPerPage, filter });

  if (response) {
    yield put(usersModule.fetchUsersWithClickedGroupsSuccess(response));
  } else {
    yield put(usersModule.fetchUsersWithClickedGroupsFail(error));
  }
}

export function* fetchUsersWithReversals({ payload: { page, itemsPerPage } }) {
  const filter = yield select(getUsersWithReversalsFilter);
  const sort = yield select(getUsersWithReversalsSort);
  const { response, error } =
    yield call(Api.users.fetchUsersWithReversals, { page, itemsPerPage, filter, sort });

  if (response) {
    yield put(usersModule.fetchUsersWithReversalsSuccess(response));
  } else {
    yield put(usersModule.fetchUsersWithReversalsFail(error));
  }
}

export default [
  takeEvery(usersModule.fetchUsers.getType(), fetchUsers),
  takeEvery(usersModule.fetchUserDetails.getType(), fetchUserDetails),
  takeEvery(usersModule.fetchUserWithTargets.getType(), fetchUserWithTargets),
  takeEvery(usersModule.fetchUsersWithClickedGroups.getType(), fetchUsersWithClickedGroups),
  takeEvery(usersModule.fetchUsersWithReversals.getType(), fetchUsersWithReversals),
];

