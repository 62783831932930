import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as clicksReportsModule from '../modules/clicksReports.module';
import * as Api from '../../core/api';

import { getFilter, getSort, getStatisticsIndex } from './selectors/clicksReports';

function* fetchClicksLeadsCount() {
  const filter = yield select(getFilter);
  const statisticsIndex = yield select(getStatisticsIndex);

  const { response, error } = yield call(Api.clicksReports.fetchClicksLeadsCount, { filter, statisticsIndex });

  if (response) {
    yield put(clicksReportsModule.fetchClicksLeadsCountSuccess(response));
  } else {
    yield put(clicksReportsModule.fetchClicksLeadsCountFail(error));
  }
}

function* fetchClicksLeadsAllCountries() {
  const filter = yield select(getFilter);
  const sort = yield select(getSort);
  const statisticsIndex = yield select(getStatisticsIndex);

  const { response, error } = yield call(
    Api.clicksReports.fetchClicksLeadsAllCountries,
    { filter, sort, statisticsIndex },
  );

  if (response) {
    yield put(clicksReportsModule.fetchClicksLeadsAllCountriesSuccess(response));
  } else {
    yield put(clicksReportsModule.fetchClicksLeadsAllCountriesFail(error));
  }
}

function* fetchClicksLeadsStatistics() {
  const filter = yield select(getFilter);
  const statisticsIndex = yield select(getStatisticsIndex);

  const { response, error } = yield call(Api.clicksReports.fetchClicksLeadsStatistics, { filter, statisticsIndex });

  if (response) {
    yield put(clicksReportsModule.fetchClicksLeadsStatisticsSuccess(response));
  } else {
    yield put(clicksReportsModule.fetchClicksLeadsStatisticsFail(error));
  }
}

function* fetchStoppedClicksGroups() {
  const filter = yield select(getFilter);
  const statisticsIndex = yield select(getStatisticsIndex);

  const { response, error } = yield call(Api.clicksReports.fetchStoppedClicksGroups, { filter, statisticsIndex });

  if (response) {
    yield put(clicksReportsModule.fetchStoppedClicksGroupsSuccess(response));
  } else {
    yield put(clicksReportsModule.fetchStoppedClicksGroupsFail(error));
  }
}

export default [
  takeEvery(clicksReportsModule.fetchClicksLeadsCount.getType(), fetchClicksLeadsCount),
  takeEvery(clicksReportsModule.fetchClicksLeadsAllCountries.getType(), fetchClicksLeadsAllCountries),
  takeEvery(
    clicksReportsModule.fetchClicksLeadsStatistics.getType(),
    fetchClicksLeadsStatistics,
  ),
  takeEvery(clicksReportsModule.fetchStoppedClicksGroups.getType(), fetchStoppedClicksGroups),
];
