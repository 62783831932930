const _ = require('lodash');

export function fetchClicksLeadsCount(state, { filter, statisticsIndex }) {
  return {
    ...state,
    statisticsIndex,
    clicksReports: {
      ...state.clicksReports,
      filter: filter || state.clicksReports.filter,
      activeFetchingCounter: state.clicksReports.activeFetchingCounter + 1,
      fetchingErrorMessages: [],
      isFetching: true,
    },
  };
}

export function fetchClicksLeadsCountSuccess(state, clicksLeadsCount) {
  const fetchingCounter = state.clicksReports.activeFetchingCounter - 1;
  const { clicksReports } = state;
  const { statisticsIndex } = clicksLeadsCount;

  return {
    ...state,
    clicksReports: {
      ...clicksReports,
      [`clicksLeadsCount${statisticsIndex}`]: clicksLeadsCount,
      activeFetchingCounter: fetchingCounter,
      isFetching: !(fetchingCounter === 0),
    },
  };
}

export function fetchClicksLeadsCountFail(state, error) {
  const fetchingCounter = state.clicksReports.activeFetchingCounter - 1;
  const errorMessage = error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    clicksReports: {
      ...state.clicksReports,
      activeFetchingCounter: fetchingCounter,
      isFetching: !(fetchingCounter === 0),
      fetchingErrorMessages: [...state.clickReports.fetchingErrorMessages, errorMessage],
    },
  };
}

export function fetchClicksLeadsAllCountries(state, { sort, filter, statisticsIndex }) {
  return {
    ...state,
    statisticsIndex,
    clicksReports: {
      ...state.clicksReports,
      filter: filter || state.clicksReports.filter,
      allCountries: {
        ...state.clicksReports.allCountries,
        sort,
      },
      activeFetchingCounter: state.clicksReports.activeFetchingCounter + 1,
      fetchingErrorMessages: [],
      isFetching: true,
    },
  };
}

export function fetchClicksLeadsAllCountriesSuccess(state, allCountries) {
  const fetchingCounter = state.clicksReports.activeFetchingCounter - 1;
  const { statisticsIndex, countries } = allCountries;

  let items = _.get(state, 'clicksReports.allCountries.items', []);
  items = items.map((item) => {
    const newItem = { ...item };
    Object.keys(newItem).forEach((key) => {
      if (key.endsWith(statisticsIndex)) {
        delete newItem[key];
      }
    });
    return newItem;
  });
  items = items.filter((item) => Object.keys(item).length > 1); // not only _id

  const newItems = [];
  countries.forEach((country) => {
    const countryId = country._id;
    const item = { ...items.find((i) => i._id === countryId) };
    items = items.filter((i) => i._id !== countryId);
    Object.keys(country).forEach((key) => {
      if (key === '_id') {
        return;
      }
      item[`${key}${statisticsIndex}`] = country[key];
    });
    newItems.push({
      ...item,
      _id: countryId,
    });
  });

  return {
    ...state,
    clicksReports: {
      ...state.clicksReports,
      allCountries: {
        ...state.clicksReports.allCountries,
        items: [...newItems, ...items],
      },
      activeFetchingCounter: fetchingCounter,
      isFetching: !(fetchingCounter === 0),
    },
  };
}

export function fetchClicksLeadsAllCountriesFail(state, error) {
  const fetchingCounter = state.clicksReports.activeFetchingCounter - 1;
  const errorMessage = error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    clicksReports: {
      ...state.clicksReports,
      activeFetchingCounter: fetchingCounter,
      isFetching: !(fetchingCounter === 0),
      fetchingErrorMessages: [...state.clicksReports.fetchingErrorMessages, errorMessage],
    },
  };
}

export function fetchClicksLeadsStatistics(state, { filter, statisticsIndex }) {
  return {
    ...state,
    statisticsIndex,
    clicksReports: {
      ...state.clicksReports,
      filter: filter || state.clicksReports.filter,
      activeFetchingCounter: state.clicksReports.activeFetchingCounter + 1,
      fetchingErrorMessages: [],
      isFetching: true,
    },
  };
}

export function fetchClicksLeadsStatisticsSuccess(state, statistics) {
  const fetchingCounter = state.clicksReports.activeFetchingCounter - 1;
  const { clicksReports } = state;
  const { series, statisticsIndex } = statistics;
  return {
    ...state,
    clicksReports: {
      ...clicksReports,
      [`chartSeries${statisticsIndex}`]: series,
      activeFetchingCounter: fetchingCounter,
      isFetching: !(fetchingCounter === 0),
    },
  };
}

export function fetchClicksLeadsStatisticsFail(state, error) {
  const fetchingCounter = state.clicksReports.activeFetchingCounter - 1;
  const errorMessage = error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    clicksReports: {
      ...state.clicksReports,
      activeFetchingCounter: fetchingCounter,
      isFetching: !(fetchingCounter === 0),
      fetchingErrorMessages: [...state.clickReports.fetchingErrorMessages, errorMessage],
    },
  };
}

export function fetchStoppedClicksGroups(state, { filter }) {
  return {
    ...state,
    clicksReports: {
      ...state.clicksReports,
      filter: filter || state.clicksReports.filter,
      activeFetchingCounter: state.clicksReports.activeFetchingCounter + 1,
      fetchingErrorMessages: [],
      isFetching: true,
    },
  };
}

export function fetchStoppedClicksGroupsSuccess(state, stoppedClicksGroups) {
  const fetchingCounter = state.clicksReports.activeFetchingCounter - 1;
  return {
    ...state,
    clicksReports: {
      ...state.clicksReports,
      stoppedClicksGroups,
      activeFetchingCounter: fetchingCounter,
      isFetching: !(fetchingCounter === 0),
    },
  };
}

export function fetchStoppedClicksGroupsFail(state, error) {
  const fetchingCounter = state.clicksReports.activeFetchingCounter - 1;
  const errorMessage = error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    clicksReports: {
      ...state.clicksReports,
      activeFetchingCounter: fetchingCounter,
      isFetching: !(fetchingCounter === 0),
      fetchingErrorMessages: [...state.clickReports.fetchingErrorMessages, errorMessage],
    },
  };
}
