import { createAction } from 'redux-act';

import * as reconciliationsLogs from '../../core/reconciliationsLogs';

export const fetchReconciliationsLogs = createAction(
  'get all api reconciliations logs',
);

export const fetchReconciliationsLogsSuccess = createAction(
  'get all api reconciliations success',
  (allLogs) => allLogs
);
export const fetchReconciliationsLogsFail = createAction(
  'get all api reconciliations fail',
  (error) => error
);

export const reducer = {
  [fetchReconciliationsLogs]: (state, payload) =>
    reconciliationsLogs.fetchReconciliationsLogs(state, payload),
  [fetchReconciliationsLogsSuccess]: (state, payload) =>
    reconciliationsLogs.fetchReconciliationsLogsSuccess(state, payload),
  [fetchReconciliationsLogsFail]: (state, payload) =>
    reconciliationsLogs.fetchReconciliationsLogsFail(state, payload),
};

