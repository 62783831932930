import { apiRequest, postApiRequest, putApiRequest, deleteApiRequest } from './_tools';

export function fetchAllocations({ page, itemsPerPage, sort }) {
  return apiRequest(`/api/allocations?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
  .catch(({ error }) => Promise.reject(error));
}

export function addAllocation(allocation) {
  return postApiRequest('/api/allocations', allocation)
    .catch(({ error }) => Promise.reject(error));
}

export function updateAllocation({ allocationId, allocation }) {
  return putApiRequest(`/api/allocations/${allocationId}`, allocation);
}

export function deleteAllocation({ allocationId }) {
  return deleteApiRequest(`/api/allocations/${allocationId}`);
}
