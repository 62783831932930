import { createAction } from 'redux-act';

import * as topQuestions from '../../core/topQuestions';

export const fetchTopQuestions = createAction(
  'get all top questions',
);
export const fetchTopQuestionsSuccess = createAction(
  'get all top questions success',
  (allUsers) => allUsers
);
export const fetchTopQuestionsFail = createAction(
  'get all top questions fail',
  (error) => error
);

export const fetchTopPreliminary = createAction(
  'get all top preliminary questions',
);
export const fetchTopPreliminarySuccess = createAction(
  'get all top preliminary questions success',
  (allUsers) => allUsers
);
export const fetchTopPreliminaryFail = createAction(
  'get all top preliminary questions fail',
  (error) => error
);

export const changeCountryLanguageCode = createAction(
  'change top questions country language code',
);
export const changeUserId = createAction(
  'change top questions user id',
);


export const reducer = {
  [fetchTopQuestions]: (state, payload) =>
    topQuestions.fetchTopQuestions(state, payload),
  [fetchTopQuestionsSuccess]: (state, payload) =>
    topQuestions.fetchTopQuestionsSuccess(state, payload),
  [fetchTopQuestionsFail]: (state, payload) =>
    topQuestions.fetchTopQuestionsFail(state, payload),

  [fetchTopPreliminary]: (state, payload) =>
    topQuestions.fetchTopPreliminary(state, payload),
  [fetchTopPreliminarySuccess]: (state, payload) =>
    topQuestions.fetchTopPreliminarySuccess(state, payload),
  [fetchTopPreliminaryFail]: (state, payload) =>
    topQuestions.fetchTopPreliminaryFail(state, payload),

  [changeCountryLanguageCode]: (state, payload) =>
    topQuestions.changeCountryLanguageCode(state, payload),
  [changeUserId]: (state, payload) =>
    topQuestions.changeUserId(state, payload),
};
