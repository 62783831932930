import { apiRequest } from './_tools';

export function fetchReconciliationsLogs({ page, itemsPerPage, sort, filter }) {
  return apiRequest(`/api/reconciliationsLogs?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&dateFrom=${filter.date.from}` +
    `&dateTo=${filter.date.to}` +
    `&clickId=${filter.clickId}` +
    `&userId=${filter.userId}` +
    `&userCountryCode=${filter.userCountryCode}` +
    `&userPlatform=${filter.userPlatform}` +
    `&endpointId=${filter.endpointId}` +
    `&subProvider=${filter.subProvider}` +
    `&surveyId=${filter.surveyId}` +
    `&providerSurveyId=${filter.providerSurveyId}` +
    `&reversalActionResult=${filter.reversalActionResult}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
    .catch(({ error }) => Promise.reject(error));
}

