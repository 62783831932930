import {
  apiRequest, postApiRequest,
} from './_tools';

export function login({ email, password }) {
  return postApiRequest('/api/auth/login', { email, password })
    .catch(({ error }) => Promise.reject(error));
}

export function logout() {
  return apiRequest('/api/auth/logout')
    .catch(({ error }) => Promise.reject(error));
}

export function getUser() {
  return apiRequest('/api/auth/user')
    .catch(({ error }) => Promise.reject(error));
}
