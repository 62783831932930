import { createAction } from 'redux-act';

import * as surveys from '../../core/surveysRanking';

export const fetchSurveys = createAction(
  'get all surveys',
);
export const fetchSurveysSuccess = createAction(
  'get all surveys success',
  (allSurveys) => allSurveys
);
export const fetchSurveysFail = createAction(
  'get all surveys fail',
  (error) => error
);

export const openChangeSurveyRankModal = createAction(
  'open survey change rank modal'
);

export const closeChangeSurveyRankModal = createAction(
  'close survey change rank modal'
);

export const changeSurveyRank = createAction(
  'change survey rank',
);
export const changeSurveyRankSuccess = createAction(
  'change survey rank success'
);
export const changeSurveyRankFail = createAction(
  'change survey rank fail'
);

export const reducer = {
  [fetchSurveys]: (state, payload) =>
    surveys.fetchSurveys(state, payload),
  [fetchSurveysSuccess]: (state, payload) =>
    surveys.fetchSurveysSuccess(state, payload),
  [fetchSurveysFail]: (state, payload) =>
    surveys.fetchSurveysFail(state, payload),

  [openChangeSurveyRankModal]: (state, payload) =>
    surveys.openChangeSurveyRankModal(state, payload),
  [closeChangeSurveyRankModal]: (state, payload) =>
    surveys.closeChangeSurveyRankModal(state, payload),
  [changeSurveyRankSuccess]: (state, payload) =>
    surveys.changeSurveyRankSuccess(state, payload),
  [changeSurveyRankFail]: (state, payload) =>
    surveys.changeSurveyRankFail(state, payload),
};
