import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as paymentsModule from '../modules/payments.module';
import * as Api from '../../core/api';

import { getSort, getFilter } from './selectors/payments';

function* fetchPayments({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);

  const { response, error }
    = yield call(Api.payments.fetchPayments, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(paymentsModule.fetchPaymentsSuccess(response));
  } else {
    yield put(paymentsModule.fetchPaymentsFail(error));
  }
}

function* changeProviderPayment({ payload: { paymentValue, paymentId } }) {
  const { response, error } =
    yield call(Api.payments.changeProviderPayment, { paymentValue, paymentId });

  if (response) {
    yield put(paymentsModule.changeProviderPaymentSuccess({
      _id: paymentId,
      providerPaymentValue: paymentValue,
    }));
  } else {
    yield put(paymentsModule.changeProviderPaymentFail(error));
  }
}

export default [
  takeEvery(paymentsModule.fetchPayments.getType(), fetchPayments),
  takeEvery(paymentsModule.changeProviderPayment.getType(), changeProviderPayment),
];
