import { createAction } from 'redux-act';

import * as extraAnswersReports from '../../core/extraAnswersReports';

export const fetchExtraAnswersReports = createAction(
  'get extra answers reports',
);
export const fetchExtraAnswersReportsSuccess = createAction(
  'get extra answers reports success',
  (allLogs) => allLogs
);
export const fetchExtraAnswersReportsFail = createAction(
  'get extra answers reports fail',
  (error) => error
);

export const reducer = {
  [fetchExtraAnswersReports]: (state, payload) =>
    extraAnswersReports.fetchExtraAnswersReports(state, payload),
  [fetchExtraAnswersReportsSuccess]: (state, payload) =>
    extraAnswersReports.fetchExtraAnswersReportsSuccess(state, payload),
  [fetchExtraAnswersReportsFail]: (state, payload) =>
    extraAnswersReports.fetchExtraAnswersReportsFail(state, payload),
};
