import { createAction } from 'redux-act';

import * as publishers from '../../core/publishers';

export const fetchPublishers = createAction(
  'get publishers',
);
export const fetchPublishersSuccess = createAction(
  'get publishers success',
  (allPublishers) => allPublishers
);
export const fetchPublishersFail = createAction(
  'get publishers fail',
  (error) => error
);

export const fetchAllPublishers = createAction(
  'get all publishers',
);
export const fetchAllPublishersSuccess = createAction(
  'get all publishers success',
  (allPublishers) => allPublishers
);
export const fetchAllPublishersFail = createAction(
  'get all publishers fail',
  (error) => error
);

export const fetchPublisherDetails = createAction(
  'get publisher details',
);
export const fetchPublisherDetailsSuccess = createAction(
  'get publisher details success',
  (publisher) => publisher
);
export const fetchPublisherDetailsFail = createAction(
  'get publisher details fail',
  (error) => error
);

export const updatePublisher = createAction(
  'update publisher'
);

export const updatePublisherSuccess = createAction(
  'update publisher success'
);

export const updatePublisherFail = createAction(
  'update publisher fail',
  (error) => error
);

export const changePublisherUpdateStatus = createAction(
  'change publisher update status'
);

export const reducer = {
  [fetchPublishers]: (state, payload) =>
    publishers.fetchPublishers(state, payload),
  [fetchPublishersSuccess]: (state, payload) =>
    publishers.fetchPublishersSuccess(state, payload),

  [fetchAllPublishersSuccess]: (state, payload) =>
    publishers.fetchAllPublishersSuccess(state, payload),

  [fetchPublisherDetailsSuccess]: (state, payload) =>
    publishers.fetchPublisherDetailsSuccess(state, payload),

  [updatePublisherSuccess]: (state, payload) =>
    publishers.updatePublisherSuccess(state, payload),
  [changePublisherUpdateStatus]: (state, payload) =>
    publishers.changePublisherUpdateStatus(state, payload),
};
