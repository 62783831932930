export const adminPrefix = '/admin';

export const app = {
  prefix: '/app/',

  managers: 'managers',
  profile: 'profile',
  mapping: 'mapping/:provider',
  mappingTolunaIpes: 'mapping/tolunaIpes',
  mappingInnovate: 'mapping/innovate',
  mappingMarketCube: 'mapping/marketCube',
  mappingPureSpectrum: 'mapping/pureSpectrum',
  mappingIpsos: 'mapping/ipsos',
  mappingSsi2: 'mapping/ssi2',
  mappingParadigm: 'mapping/paradigm',
  mappingGlobalSurvey: 'mapping/globalSurvey',
  mappingProdege: 'mapping/prodege',
  mappingPollfish: 'mapping/pollfish',
  mappingMorningConsult: 'mapping/morningConsult',
  mappingSSIRex: 'mapping/ssiRex',
  editMapping: 'mapping/:provider/:questionId/:country/:index',
  publishers: 'publishers',
  publisherDetails: 'publishers/:publisherId',
  users: 'users',
  userDetails: 'users/:userId',
  usersByAnswers: 'users/usersByAnswers',
  usersWithClickedGroups: 'users/usersWithClickedGroups',
  usersWithReversals: 'users/usersWithReversals',
  surveys: 'surveys',
  surveysRanking: 'surveysRanking',
  surveysView: 'surveysView',
  surveysGroups: 'surveysGroups',
  questions: 'questions',
  topQuestions: 'topQuestions',
  topPreliminary: 'topPreliminary',
  clicks: 'clicks',
  clicksReversals: 'clicks/reversals',
  apps: 'apps',
  saveLeadLogs: 'logs/saveLeadLogs',
  cronLogs: 'logs/cronLogs',
  topSurveysReports: 'reports/topSurveys',
  clicksReports: 'reports/clicks',
  abTesting: 'reports/abTesting',
  extraAnswersReports: 'reports/extraAnswers',
  cities: 'cities',
  allocations: 'allocations',
  feasibility: 'feasibility',
  rankAdjustments: 'rankAdjustments',
  globalSettings: 'settings/globalSettings',
  payments: 'payments',
  desktopMobileSurveys: 'desktopMobileSurveys',
  surveyQualificationsLogs: 'logs/surveyQualificationsLogs',
  reconciliationsLogs: 'logs/reconciliationsLogs',
};

export const auth = {
  prefix: '/auth/',

  login: 'login',
  logout: 'logout',
};

export function getMappingDetailsLink(provider, country, questionId, index) {
  return `${adminPrefix}${app.prefix}mapping/${provider}/${questionId}/${country}/${index || 0}`;
}

export function getMappingDetailsLinkNew(provider, country, questionId, index) {
  return `${adminPrefix}${app.prefix}mapping/${provider}?questionId=${questionId}&country=${country}&index=${index}`;
}

export function getPublisherDetailsLink(publisherId) {
  return `${adminPrefix}${app.prefix}${app.publishers}/${publisherId}`;
}

export function getUserDetailsLink(userId) {
  return `${adminPrefix}${app.prefix}${app.users}/${userId}`;
}

export function getClicksFilteredByInternalUserIdLink(internalUserId) {
  return `${adminPrefix}${app.prefix}${app.clicks}?internalUserId=${internalUserId}`;
}
