import { createAction } from 'redux-act';

import * as reports from '../../core/desktopMobileSurveys';

export const fetchAllCountries = createAction(
  'get desktop mobile surveys all countries',
);
export const fetchAllCountriesSuccess = createAction(
  'get desktop mobile surveys all countries success',
  (allApps) => allApps
);
export const fetchAllCountriesFail = createAction(
  'get desktop mobile surveys all countries fail',
  (error) => error
);

export const reducer = {
  [fetchAllCountries]: (state, payload) =>
    reports.fetchAllCountries(state, payload),
  [fetchAllCountriesSuccess]: (state, payload) =>
    reports.fetchAllCountriesSuccess(state, payload),
  [fetchAllCountriesFail]: (state, payload) =>
    reports.fetchAllCountriesFail(state, payload),
};
