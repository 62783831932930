import { apiRequest, postApiRequest, putApiRequest } from './_tools';

export function fetchApps({ page, itemsPerPage, sort, filter }) {
  const name = encodeURIComponent(filter.name);
  const userEmail = encodeURIComponent(filter.userEmail);
  const typeParam = filter.types.map(type => `types=${type}`).join('&');

  return apiRequest(`/api/apps?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&name=${name}` +
    `&userEmail=${userEmail}` +
    `&${typeParam}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
  .catch(({ error }) => Promise.reject(error));
}

export function addApp(app) {
  return postApiRequest('/api/apps', app)
    .catch(({ error }) => Promise.reject(error));
}

export function updateApp({ appId, app }) {
  return putApiRequest(`/api/apps/${appId}`, app);
}
