export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'timestamp',
    order: -1,
  },
  filter: {
    surveyProvider: '',
    userId: '',
    clickId: '',
    type: '',
    leadStatus: '',
    date: {
      from: '',
      to: '',
    },
  },
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'timestamp',
    order: -1,
  },
  filter: {
    surveyProvider: '',
    userId: '',
    clickId: '',
    type: '',
    leadStatus: '',
    date: {
      from: '',
      to: '',
    },
  },
  isFetching: false,
  fetchingErrorMessage: '',
};
