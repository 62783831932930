import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as cronLogsModule from '../modules/cronLogs.module';
import * as Api from '../../core/api';
import { getSort, getFilter } from './selectors/cronLogs';

function* fetchCronLogs({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);

  const { response, error } =
    yield call(Api.cronLogs.fetchCronLogs, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(cronLogsModule.fetchCronLogsSuccess(response));
  } else {
    yield put(cronLogsModule.fetchCronLogsFail(error));
  }
}

export default [
  takeEvery(cronLogsModule.fetchCronLogs.getType(), fetchCronLogs),
];
