import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as feasibilityModule from '../modules/feasibility.module';
import * as Api from '../../core/api';
import { getFilter } from './selectors/feasibility';

function* fetchFeasibility() {
  const filter = yield select(getFilter);
  const { response, error } =
    yield call(
      Api.feasibility.fetchFeasibility,
      { filter },
    );

  if (response) {
    yield put(feasibilityModule.fetchFeasibilitySuccess(response));
  } else {
    yield put(feasibilityModule.fetchFeasibilityFail(error));
  }
}

function* updateFeasibilityConstants({ payload: { countryCode, constants } }) {
  const { response, error } =
    yield call(Api.feasibility.updateConstants, { countryCode, constants });

  if (response) {
    yield put(feasibilityModule.updateFeasibilityConstantsSuccess({ countryCode, constants }));
    yield put(feasibilityModule.changeConstantsUpdateStatus('Success'));
  } else {
    yield put(feasibilityModule.updateFeasibilityConstantsFail(error));
    yield put(feasibilityModule.changeConstantsUpdateStatus('Failed'));
  }
}

export default [
  takeEvery(feasibilityModule.fetchFeasibility.getType(), fetchFeasibility),
  takeEvery(feasibilityModule.updateFeasibilityConstants.getType(), updateFeasibilityConstants),
];
