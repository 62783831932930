import { createAction } from 'redux-act';

import * as auth from '../../core/auth';

export const login = createAction(
  'login',
);
export const loginSuccess = createAction(
  'login success',
  (user) => user
);
export const loginFail = createAction(
  'login fail',
  (error) => error
);

export const logout = createAction(
  'get campaign details',
);
export const logoutSuccess = createAction(
  'get campaign details success',
);
export const logoutFail = createAction(
  'get campaign details fail',
  (error) => error
);

export const sessionRestored = createAction(
  'session restored',
  (user) => user
);

export const reducer = {
  [loginSuccess]: (state, payload) =>
    auth.loginSuccess(state, payload),
  [sessionRestored]: (state, payload) =>
    auth.sessionRestored(state, payload),
};
