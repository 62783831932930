import { createAction } from 'redux-act';

import * as surveys from '../../core/topSurveys';

export const fetchSurveys = createAction(
  'get all surveys',
);
export const fetchSurveysSuccess = createAction(
  'get all surveys success',
  (allSurveys) => allSurveys
);
export const fetchSurveysFail = createAction(
  'get all surveys fail',
  (error) => error
);

export const reducer = {
  [fetchSurveys]: (state, payload) =>
    surveys.fetchSurveys(state, payload),
  [fetchSurveysSuccess]: (state, payload) =>
    surveys.fetchSurveysSuccess(state, payload),
  [fetchSurveysFail]: (state, payload) =>
    surveys.fetchSurveysFail(state, payload),
};
