import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as clicksModule from '../modules/clicks.module';
import * as Api from '../../core/api';
import { getSort, getFilter } from './selectors/clicks';

function* fetchClicks({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);
  const { response, error } =
    yield call(Api.clicks.fetchClicks, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(clicksModule.fetchClicksSuccess(response));
  } else {
    yield put(clicksModule.fetchClicksFail(error));
  }
}

export default [
  takeEvery(clicksModule.fetchClicks.getType(), fetchClicks),
];

