import { createAction } from 'redux-act';

import * as usersByAnswers from '../../core/usersByAnswers';

export const fetchUsers = createAction(
  'get all users by answers',
);
export const fetchUsersSuccess = createAction(
  'get all users by answers success',
  (allUsers) => allUsers
);
export const fetchUsersFail = createAction(
  'get all users by answers fail',
  (error) => error
);

export const changeCountryLanguage = createAction(
  'change users by answers country language',
);

export const reducer = {
  [fetchUsers]: (state, payload) =>
    usersByAnswers.fetchUsers(state, payload),
  [fetchUsersSuccess]: (state, payload) =>
    usersByAnswers.fetchUsersSuccess(state, payload),
  [fetchUsersFail]: (state, payload) =>
    usersByAnswers.fetchUsersFail(state, payload),

  [changeCountryLanguage]: (state, payload) =>
    usersByAnswers.changeCountryLanguage(state, payload),
};
