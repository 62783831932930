import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as usersByAnswersModule from '../modules/usersByAnswers.module';
import * as Api from '../../core/api';
import { getQuestions, getCountryLanguageCode } from './selectors/usersByAnswers';

function* fetchUsers({ payload: { page, itemsPerPage } }) {
  const questions = yield select(getQuestions);
  const countryLanguageCode = yield select(getCountryLanguageCode);
  const { response, error } =
    yield call(
      Api.users.fetchUsersByAnswers,
      { page, itemsPerPage, questions, countryLanguageCode },
    );

  if (response) {
    yield put(usersByAnswersModule.fetchUsersSuccess(response));
  } else {
    yield put(usersByAnswersModule.fetchUsersFail(error));
  }
}

export default [
  takeEvery(usersByAnswersModule.fetchUsers.getType(), fetchUsers),
];

