export function fetchCities(state, { page, sort, filter }) {
  return {
    ...state,
    cities: {
      ...state.cities,
      currentPage: page,
      sort: sort || state.cities.sort,
      filter: filter || state.cities.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchCitiesSuccess(state, cities) {
  return {
    ...state,
    cities: {
      ...state.cities,
      numberOfPages: cities.numberOfPages,
      count: cities.count,
      items: cities.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchCitiesFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    cities: {
      ...state.cities,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}
