export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'questionId',
    order: 1,
  },
  filter: {
    name: '',
    questionId: '',
    countryLanguageCode: '',
  },
  allQuestionsByCountryLanguageCode: [],
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'questionId',
    order: 1,
  },
  filter: {
    name: '',
    questionId: '',
    isRedHerring: '',
    countryLanguageCode: '',
  },
  allQuestionsByCountryLanguageCode: [],
  isFetching: false,
  fetchingErrorMessage: '',
};
