import { apiRequest, putApiRequest } from './_tools';

export function fetchSurveys({ page, itemsPerPage, sort, filter }) {
  const name = encodeURIComponent(filter.name);
  const accountName = encodeURIComponent(filter.accountName);
  return apiRequest(`/api/surveysByPages?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&name=${name}` +
    `&accountName=${accountName}` +
    `&provider=${filter.provider}` +
    `&uniqueNumber=${filter.uniqueNumber}` +
    `&active=${filter.active}` +
    `&withAdditionalQuotas=${filter.withAdditionalQuotas}` +
    `&zeroRank=${filter.zeroRank}` +
    `&isPreferred=${filter.isPreferred}` +
    `&reContact=${filter.reContact}` +
    `&mobileResponsive=${filter.mobileResponsive}` +
    `&mobileOnly=${filter.mobileOnly}` +
    `&countryLanguageId=${filter.countryLanguageId}` +
    `&countryLanguageCode=${filter.countryLanguageCode}` +
    `&endDateFrom=${filter.endDate.from}` +
    `&endDateTo=${filter.endDate.to}` +
    `&createDateFrom=${filter.createDate.from}` +
    `&createDateTo=${filter.createDate.to}` +
    `&surveyId=${filter.surveyId}` +
    `&vertical=${filter.vertical}` +
    `&surveyGroupsIds=${filter.surveyGroupsIds}` +
    `&hasOurLOI=${filter.hasOurLOI}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
    .catch(({ error }) => Promise.reject(error));
}

export function fetchSurveyResponseCodesStats(surveyId) {
  return apiRequest(`/api/surveysByPages/${surveyId}/responseCodesStats`)
    .catch(({ error }) => Promise.reject(error));
}

export function changeSurveyPreferredValue({ surveyId, newPreferredValue }) {
  return putApiRequest(`/api/surveys/${surveyId}/preferred`, { newPreferredValue })
    .catch(({ error }) => Promise.reject(error));
}
