import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as surveyQualificationsModule from '../modules/surveyQualificationsLogs.module';
import * as Api from '../../core/api';
import { getSort, getFilter } from './selectors/surveyQualificationsLogs';

function* fetchSurveyQualificationsLogs({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);

  const { response, error } =
    yield call(Api.surveyQualificationsLogs.fetchSurveyQualificationsLogs, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(surveyQualificationsModule.fetchSurveyQualificationsLogsSuccess(response));
  } else {
    yield put(surveyQualificationsModule.fetchSurveyQualificationsLogsFail(error));
  }
}

export default [
  takeEvery(surveyQualificationsModule.fetchSurveyQualificationsLogs.getType(), fetchSurveyQualificationsLogs),
];
