export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'createDate',
    order: -1,
  },
  statsModal: {
    nowFetching: null,
    showModal: false,
    data: [],
    fetchingErrorMessage: '',
    type: '',
  },
  filter: {
    name: '',
    displayName: '',
    uniqueNumber: '',
    accountName: '',
    provider: [],
    active: '',
    withAdditionalQuotas: '',
    isPreferred: '',
    reContact: '',
    mobileResponsive: '',
    mobileOnly: '',
    countryLanguageId: '',
    countryLanguageCode: '',
    surveyGroupsIds: '',
    vertical: '',
    endDate: {
      from: '',
      to: '',
    },
    createDate: {
      from: '',
      to: '',
    },
    surveyId: '',
    hasOurLOI: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'createDate',
    order: -1,
  },
  statsModal: {
    nowFetching: null,
    showModal: false,
    data: [],
    fetchingErrorMessage: '',
    type: '',
  },
  filter: {
    name: '',
    uniqueNumber: '',
    accountName: '',
    provider: ['federated'],
    active: 'true',
    withAdditionalQuotas: '',
    isPreferred: '',
    reContact: '',
    mobileResponsive: '',
    mobileOnly: '',
    countryLanguageId: '',
    vertical: '',
    countryLanguageCode: '',
    surveyGroupsIds: '',
    endDate: {
      from: '',
      to: '',
    },
    createDate: {
      from: '',
      to: '',
    },
    surveyId: '',
    hasOurLOI: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};
