import { createAction } from 'redux-act';

import * as clicksReports from '../../core/clicksReports';

export const fetchClicksLeadsCount = createAction(
  'get clicks leads count',
);
export const fetchClicksLeadsCountSuccess = createAction(
  'get clicks leads count success',
  (allApps) => allApps,
);
export const fetchClicksLeadsCountFail = createAction(
  'get clicks leads count fail',
  (error) => error,
);

export const fetchClicksLeadsAllCountries = createAction(
  'get clicks leads all countries',
);
export const fetchClicksLeadsAllCountriesSuccess = createAction(
  'get clicks leads all countries success',
  (allApps) => allApps,
);
export const fetchClicksLeadsAllCountriesFail = createAction(
  'get clicks leads all countries fail',
  (error) => error,
);

export const fetchClicksLeadsStatistics = createAction(
  'get clicks statistics',
);
export const fetchClicksLeadsStatisticsSuccess = createAction(
  'get clicks statistics success',
  (allApps) => allApps,
);
export const fetchClicksLeadsStatisticsFail = createAction(
  'get clicks statistics fail',
  (error) => error,
);

export const fetchStoppedClicksGroups = createAction(
  'get clicks stopped clicks groups',
);
export const fetchStoppedClicksGroupsSuccess = createAction(
  'get clicks stopped clicks groups success',
  (allApps) => allApps,
);
export const fetchStoppedClicksGroupsFail = createAction(
  'get clicks stopped clicks groups fail',
  (error) => error,
);

export const reducer = {
  [fetchClicksLeadsCount]: (state, payload) => clicksReports.fetchClicksLeadsCount(state, payload),
  [fetchClicksLeadsCountSuccess]: (state, payload) => clicksReports.fetchClicksLeadsCountSuccess(state, payload),
  [fetchClicksLeadsCountFail]: (state, payload) => clicksReports.fetchClicksLeadsCountFail(state, payload),

  [fetchClicksLeadsAllCountries]: (state, payload) => clicksReports.fetchClicksLeadsAllCountries(state, payload),
  [fetchClicksLeadsAllCountriesSuccess]: (state, payload) => (
    clicksReports.fetchClicksLeadsAllCountriesSuccess(state, payload)
  ),
  [fetchClicksLeadsAllCountriesFail]: (state, payload) => (
    clicksReports.fetchClicksLeadsAllCountriesFail(state, payload)
  ),

  [fetchClicksLeadsStatistics]: (state, payload) => clicksReports.fetchClicksLeadsStatistics(state, payload),
  [fetchClicksLeadsStatisticsSuccess]: (state, payload) => (
    clicksReports.fetchClicksLeadsStatisticsSuccess(state, payload)
  ),
  [fetchClicksLeadsStatisticsFail]: (state, payload) => clicksReports.fetchClicksLeadsStatisticsFail(state, payload),

  [fetchStoppedClicksGroups]: (state, payload) => clicksReports.fetchStoppedClicksGroups(state, payload),
  [fetchStoppedClicksGroupsSuccess]: (state, payload) => clicksReports.fetchStoppedClicksGroupsSuccess(state, payload),
  [fetchStoppedClicksGroupsFail]: (state, payload) => clicksReports.fetchStoppedClicksGroupsFail(state, payload),
};
