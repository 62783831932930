import { apiRequest, putApiRequest } from './_tools';

export function fetchFeasibility({ filter }) {
  return apiRequest('/api/feasibility/constants' +
    `?countryCode=${filter.countryCode}`)
    .catch(({ error }) => Promise.reject(error));
}

export function updateConstants({ countryCode, constants }) {
  return putApiRequest('/api/feasibility/constants', { countryCode, constants });
}
