export function fetchSurveys(state, { page, sort, filter }) {
  return {
    ...state,
    surveysRanking: {
      ...state.surveysRanking,
      currentPage: page,
      sort: sort || state.surveysRanking.sort,
      filter: filter || state.surveysRanking.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchSurveysSuccess(state, surveys) {
  return {
    ...state,
    surveysRanking: {
      ...state.surveysRanking,
      numberOfPages: surveys.numberOfPages,
      count: surveys.count,
      items: surveys.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchSurveysFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    surveysRanking: {
      ...state.surveysRanking,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function openChangeSurveyRankModal(state, survey) {
  return {
    ...state,
    surveysRanking: {
      ...state.surveysRanking,
      changeRankModal: {
        ...state.surveysRanking.changeRankModal,
        showModal: true,
        survey: survey || {},
      },
    },
  };
}

export function closeChangeSurveyRankModal(state) {
  return {
    ...state,
    surveysRanking: {
      ...state.surveysRanking,
      changeRankModal: {
        showModal: false,
        survey: {},
      },
    },
  };
}

export function changeSurveyRankSuccess(state, updatedSurvey) {
  const oldSurveyIndex = state.surveysRanking.items.findIndex(x => x._id === updatedSurvey._id);

  if (oldSurveyIndex === -1) {
    return {
      ...state,
      surveysRanking: {
        ...state.surveysRanking,
        changeRankModal: {
          showModal: false,
          errorMessage: '',
          survey: {},
        },
      },
    };
  }

  const oldSurvey = state.surveysRanking.items[oldSurveyIndex];

  return {
    ...state,
    surveysRanking: {
      ...state.surveysRanking,
      items: [
        ...state.surveysRanking.items.slice(0, oldSurveyIndex),
        {
          ...oldSurvey,
          rank: updatedSurvey.rankValue,
          isManualRank: updatedSurvey.isManualRank,
        },
        ...state.surveysRanking.items.slice(oldSurveyIndex + 1),
      ],
      changeRankModal: {
        showModal: false,
        errorMessage: '',
        survey: {},
      },
    },
  };
}

export function changeSurveyRankFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    surveysRanking: {
      ...state.surveysRanking,
      changeRankModal: {
        ...state.surveysRanking.changeRankModal,
        errorMessage,
      },
    },
  };
}
