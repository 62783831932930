export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'state',
    order: 1,
  },
  filter: {
    city: '',
    state: '',
    metroCode: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'state',
    order: 1,
  },
  filter: {
    city: '',
    state: '',
    metroCode: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};
