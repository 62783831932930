export const emptyFilter = {
  id: '',
  clickId: '',
  internalUserId: '',
  userId: '',
  userCountryCode: '',
  surveyId: '',
  surveyProvider: '',
};

export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'clickDate',
    order: -1,
  },
  filter: emptyFilter,
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'clickDate',
    order: -1,
  },
  filter: emptyFilter,
  isFetching: false,
  fetchingErrorMessage: '',
  showModal: false,
  modalQualifications: [],
};
