export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  provider: '',
  fetchingErrorMessage: '',
  isFetching: false,
  newQuestionAdded: {
    state: false,
  },
  filter: {
    questionName: '',
    country: '',
  },
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  provider: '',
  fetchingErrorMessage: '',
  isFetching: false,
  newQuestionAdded: {
    state: false,
  },
  filter: {
    questionName: '',
    country: '',
  },
};
