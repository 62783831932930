import React  from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import routesConfig from './routesConfig.auth';

const router = createBrowserRouter(routesConfig);

export default function App({ store }) {
  return (
    <Provider store={store}>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </Provider>
  );
}

App.propTypes = {
  store: PropTypes.object.isRequired,
};
