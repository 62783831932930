import { createAction } from 'redux-act';

import * as clicks from '../../core/clicks';

export const fetchClicks = createAction(
  'get all clicks',
);
export const fetchClicksSuccess = createAction(
  'get all clicks success',
  (allclicks) => allclicks
);
export const fetchClicksFail = createAction(
  'get all clicks fail',
  (error) => error
);

export const reducer = {
  [fetchClicks]: (state, payload) =>
    clicks.fetchClicks(state, payload),
  [fetchClicksSuccess]: (state, payload) =>
    clicks.fetchClicksSuccess(state, payload),
  [fetchClicksFail]: (state, payload) =>
    clicks.fetchClicksFail(state, payload),
};
