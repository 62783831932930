export function fetchSurveys(state, { page, sort, filter }) {
  return {
    ...state,
    surveysView: {
      ...state.surveysView,
      currentPage: page,
      sort: sort || state.surveysView.sort,
      filter: filter || state.surveysView.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchSurveysSuccess(state, surveys) {
  return {
    ...state,
    surveysView: {
      ...state.surveysView,
      numberOfPages: surveys.numberOfPages,
      count: surveys.count,
      items: surveys.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchSurveysFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    surveysView: {
      ...state.surveysView,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}
