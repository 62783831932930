// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/bootstrap/dist/css/bootstrap.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/flag-icons/css/flag-icons.min.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/react-datepicker/dist/react-datepicker.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  padding-top: 70px;
}

.margin-bottom-45 {
  margin-bottom: 45px;
}

.top-buffer {
  margin-top: 20px;
}

.modal-container {
  position: relative;
}
.modal-container .modal, .modal-container .modal-backdrop {
  position: absolute;
}

.error {
  color: red!important;
}

.input-number-short {
  width: 35px;
}

.select-width {
  width: 180px;
}

.dnd-element {
  border: 1px solid black;
  padding: 7px;
}

.with-asterisk:after {
  content: "*";
  color: red;
}

.large-modal-90w {
  width: 90%!important;
  max-width: none!important;
}

.row.row-grid{
    margin-bottom: 15px;
}

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 9999;
  background: rgba(0, 0, 0, 0);

  &.spinner-overlay {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
  }
}

.custom-tooltip {
  max-width: 900px;
  display: block;
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: normal;
}
`, "",{"version":3,"sources":["webpack://./scss/app.scss"],"names":[],"mappings":"AAIA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,oBAAoB;EACpB,yBAAyB;AAC3B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,4BAA4B;;EAE5B;IACE,iBAAiB;IACjB,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,MAAM;EACR;AACF;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,qBAAqB;EACrB,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":["@import \"~bootstrap/dist/css/bootstrap\";\n@import \"~flag-icons/css/flag-icons.min.css\";\n@import \"~react-datepicker/dist/react-datepicker.css\";\n\nbody {\n  padding-top: 70px;\n}\n\n.margin-bottom-45 {\n  margin-bottom: 45px;\n}\n\n.top-buffer {\n  margin-top: 20px;\n}\n\n.modal-container {\n  position: relative;\n}\n.modal-container .modal, .modal-container .modal-backdrop {\n  position: absolute;\n}\n\n.error {\n  color: red!important;\n}\n\n.input-number-short {\n  width: 35px;\n}\n\n.select-width {\n  width: 180px;\n}\n\n.dnd-element {\n  border: 1px solid black;\n  padding: 7px;\n}\n\n.with-asterisk:after {\n  content: \"*\";\n  color: red;\n}\n\n.large-modal-90w {\n  width: 90%!important;\n  max-width: none!important;\n}\n\n.row.row-grid{\n    margin-bottom: 15px;\n}\n\n.overlay {\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  z-index: 9999;\n  background: rgba(0, 0, 0, 0);\n\n  &.spinner-overlay {\n    margin-left: auto;\n    margin-right: auto;\n    left: 0;\n    right: 0;\n    top: 0;\n  }\n}\n\n.custom-tooltip {\n  max-width: 900px;\n  display: block;\n  word-break: break-all;\n  overflow-wrap: break-word;\n  white-space: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
