export function fetchRankAdjustments(state, { page, sort, filter }) {
  return {
    ...state,
    rankAdjustments: {
      ...state.rankAdjustments,
      currentPage: page || state.rankAdjustments.currentPage,
      sort: sort || state.rankAdjustments.sort,
      filter: filter || state.rankAdjustments.filter,
    },
  };
}

export function fetchRankAdjustmentsSuccess(state, rankAdjustments) {
  return {
    ...state,
    rankAdjustments: {
      ...state.rankAdjustments,
      numberOfPages: rankAdjustments.numberOfPages,
      count: rankAdjustments.count,
      items: rankAdjustments.items,
    },
  };
}

export function addRankAdjustmentSuccess(state, rankAdjustment) {
  return {
    ...state,
    rankAdjustments: {
      ...state.rankAdjustments,
      items: [
        ...state.rankAdjustments.items,
        rankAdjustment,
      ],
    },
  };
}

export function openUpdateRankAdjustment(state, { rankAdjustment }) {
  return {
    ...state,
    editRankAdjustment: {
      showModal: true,
      rankAdjustment,
    },
  };
}

export function closeUpdateRankAdjustment(state) {
  return {
    ...state,
    editRankAdjustment: {
      showModal: false,
    },
  };
}

export function updateRankAdjustmentSuccess(state, { rankAdjustmentId, rankAdjustment: rankAdjustmentData }) {
  const rankAdjustmentIndex = state.rankAdjustments.items.findIndex(x => x._id === rankAdjustmentId);
  const rankAdjustment = state.rankAdjustments.items.find(x => x._id === rankAdjustmentId);
  if (!rankAdjustment) {
    return state;
  }
  return {
    ...state,
    rankAdjustments: {
      ...state.rankAdjustments,
      items: [
        ...state.rankAdjustments.items.slice(0, rankAdjustmentIndex),
        {
          ...rankAdjustment,
          accountName: rankAdjustmentData.accountName,
          value: rankAdjustmentData.value,
        },
        ...state.rankAdjustments.items.slice(rankAdjustmentIndex + 1),
      ],
    },
  };
}

export function deleteRankAdjustmentSuccess(state, { rankAdjustmentId }) {
  const rankAdjustmentIndex = state.rankAdjustments.items.findIndex(x => x._id === rankAdjustmentId);
  const rankAdjustment = state.rankAdjustments.items.find(x => x._id === rankAdjustmentId);
  if (!rankAdjustment) {
    return state;
  }
  return {
    ...state,
    rankAdjustments: {
      ...state.rankAdjustments,
      items: [
        ...state.rankAdjustments.items.slice(0, rankAdjustmentIndex),
        ...state.rankAdjustments.items.slice(rankAdjustmentIndex + 1),
      ],
    },
  };
}
