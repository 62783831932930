import { takeEvery, put, call, fork, select } from 'redux-saga/effects';

import * as surveysModule from '../modules/surveys.module';
import * as Api from '../../core/api';
import { getSort, getFilter } from './selectors/surveys';

export function* fetchSurveys({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);
  const { response, error } =
    yield call(Api.surveys.fetchSurveys, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(surveysModule.fetchSurveysSuccess(response));
  } else {
    yield put(surveysModule.fetchSurveysFail(error));
  }
}

export function* fetchSurveyResponseCodesStats({ payload: { surveyId } }) {
  const { response, error } =
    yield call(Api.surveys.fetchSurveyResponseCodesStats, surveyId);

  if (response) {
    yield put(surveysModule.fetchSurveyResponseCodesStatsSuccess(response));
  } else {
    yield put(surveysModule.fetchSurveyResponseCodesStatsFail(error));
  }
}

export function* changeSurveyPreferredValue({ payload: { surveyId, newPreferredValue } }) {
  const { response, error } =
    yield call(Api.surveys.changeSurveyPreferredValue, { surveyId, newPreferredValue });

  if (response) {
    yield put(surveysModule.changeSurveyPreferredValueSuccess({ surveyId, newPreferredValue }));
  } else {
    yield put(surveysModule.changeSurveyPreferredValueFail(error));
  }
}


export default [
  takeEvery(surveysModule.fetchSurveys.getType(), fetchSurveys),
  takeEvery(surveysModule.fetchSurveyResponseCodesStats.getType(), fetchSurveyResponseCodesStats),
  takeEvery(surveysModule.changeSurveyPreferredValue.getType(), changeSurveyPreferredValue),
];

