export function fetchAllCountries(state, { sort, filter }) {
  return {
    ...state,
    desktopMobileSurveysReports: {
      ...state.desktopMobileSurveysReports,
      filter: filter || state.desktopMobileSurveysReports.filter,
      allCountries: {
        ...state.desktopMobileSurveysReports.allCountries,
        sort,
      },
      activeFetchingCounter: state.desktopMobileSurveysReports.activeFetchingCounter + 1,
      fetchingErrorMessages: [],
      isFetching: true,
    },
  };
}

export function fetchAllCountriesSuccess(state, items) {
  const fetchingCounter = state.desktopMobileSurveysReports.activeFetchingCounter - 1;

  return {
    ...state,
    desktopMobileSurveysReports: {
      ...state.desktopMobileSurveysReports,
      allCountries: {
        ...state.desktopMobileSurveysReports.allCountries,
        items,
      },
      activeFetchingCounter: fetchingCounter,
      isFetching: !(fetchingCounter === 0),
    },
  };
}

export function fetchAllCountriesFail(state, error) {
  const fetchingCounter = state.desktopMobileSurveysReports.activeFetchingCounter - 1;
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    desktopMobileSurveysReports: {
      ...state.desktopMobileSurveysReports,
      activeFetchingCounter: fetchingCounter,
      isFetching: !(fetchingCounter === 0),
      fetchingErrorMessages: [...state.desktopMobileSurveysReports.fetchingErrorMessages, errorMessage],
    },
  };
}
