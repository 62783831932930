export function sessionRestored(state, manager) {
  return {
    ...state,
    manager,
  };
}

export function logoutSuccess(state) {
  return {
    ...state,
    manager: {},
  };
}

export function loginSuccess(state, user) {
  return {
    ...state,
    manager: user,
  };
}
