export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'startDate',
    order: -1,
  },
  filter: {
    name: '',
    startDate: {
      from: '',
      to: '',
    },
    isWithErrors: '',
    isWithGlobalError: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'startDate',
    order: -1,
  },
  filter: {
    name: '',
    startDate: {
      from: '',
      to: '',
    },
    isWithErrors: '',
    isWithGlobalError: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};
