import { apiRequest } from './_tools';

export function fetchExtraAnswersReports({ page, itemsPerPage, filter }) {
  return apiRequest(`/api/reports/api/extraAnswers?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&countryCode=${filter.countryCode}` +
    `&dateFrom=${filter.date.from}` +
    `&appId=${filter.appId}` +
    `&dateTo=${filter.date.to}`)
    .catch(({ error }) => Promise.reject(error));
}
