import { apiRequest } from './_tools';

export function fetchSurveys({ page, itemsPerPage, sort, filter }) {
  return apiRequest(`/api/surveysByPages/view?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&internalOrExternalUserId=${filter.internalOrExternalUserId}` +
    `&rankType=${filter.rankType}` +
    `&numberOfAdditionalQuestions=${filter.numberOfAdditionalQuestions}` +
    `&provider=${filter.provider}` +
    `&targetingView=${filter.targetingView}` +
    `&mobileResponsive=${filter.mobileResponsive}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
    .catch(({ error }) => Promise.reject(error));
}
