import _ from 'lodash';
import { sendFileRequest, postApiRequest } from './_tools';

export async function fetchClicks({
  page, itemsPerPage, sort, filter,
}) {
  const body = {
    ..._.omit(filter, ['clickDate', 'leadDate']),
    itemsPerPage,
    page,
    sortKey: sort.key,
    order: sort.order,
    clickDateFrom: _.get(filter, 'clickDate.from', ''),
    clickDateTo: _.get(filter, 'clickDate.to'),
    leadDateFrom: _.get(filter, 'leadDate.from', ''),
    leadDateTo: _.get(filter, 'leadDate.to'),
  };
  try {
    return postApiRequest('/api/clicksByPages', body);
  } catch ({ error }) {
    throw error;
  }
}

export async function processReversals({ clickIds }) {
  try {
    return postApiRequest('/api/clicks/completeReverse', clickIds);
  } catch ({ error }) {
    throw error;
  }
}

export async function fetchClickIds({ file }) {
  try {
    return sendFileRequest('/api/clicks/reverse', file);
  } catch ({ error }) {
    throw error;
  }
}
