import moment from 'moment-timezone';

export const test = {
  filter: {
    date: {
      from: moment.tz('America/Los_Angeles').startOf('day').format('MM/DD/YYYY'),
      to: moment.tz('America/Los_Angeles').startOf('day').format('MM/DD/YYYY'),
    },
    countryCode: '',
  },
  items: [],
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  items: [],
  filter: {
    date: {
      from: moment.tz('America/Los_Angeles').startOf('day').format('MM/DD/YYYY'),
      to: moment.tz('America/Los_Angeles').startOf('day').format('MM/DD/YYYY'),
    },
    countryCode: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};
