import fetch from 'isomorphic-fetch';

export const HEADERS = {
  JSON: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export function apiRequest(url, options) {
  return fetch(url, Object.assign({}, options, { credentials: 'include' }))
    .then(response => {
      if (response.ok) {
        return response.json().then((data) => ({ response: data }));
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json()
          .then((json) => ({ error: json, status: response.status }));
      }
      return response.text()
        .then((text) => ({ error: text, status: response.status }));
    });
}

export function putApiRequest(url, body, options = { headers: {} }) {
  return apiRequest(url, {
    ...options,
    method: 'PUT',
    headers: {
      ...options.headers,
      ...HEADERS.JSON,
    },
    body: JSON.stringify(body),
  });
}

export function postApiRequest(url, body, options = { headers: {} }) {
  return apiRequest(url, {
    ...options,
    method: 'POST',
    headers: {
      ...options.headers,
      ...HEADERS.JSON,
    },
    body: JSON.stringify(body),
  });
}


export function deleteApiRequest(url, options = { headers: {} }) {
  return apiRequest(url, {
    ...options,
    method: 'DELETE',
    headers: options.headers,
  });
}

export function sendFileRequest(url, file, options = { headers: {} }) {
  const formData = new FormData();
  formData.append('attachment', file, file.name);

  return apiRequest(url, {
    ...options,
    method: 'POST',
    headers: {
      ...options.headers,
      Accept: 'application/json',
    },
    body: formData,
  });
}
