export function fetchClicks(state, { page, sort, filter }) {
  return {
    ...state,
    clicksReversals: {
      ...state.clicksReversals,
      currentPage: page,
      sort: sort || state.clicksReversals.sort,
      filter: filter || state.clicksReversals.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}
export function fetchClicksSuccess(state, clicks) {
  return {
    ...state,
    clicksReversals: {
      ...state.clicksReversals,
      numberOfPages: clicks.numberOfPages,
      count: clicks.count,
      items: clicks.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}
export function fetchClicksFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    clicksReversals: {
      ...state.clicksReversals,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function fetchClickIds(state, { page, sort, filter }) {
  return {
    ...state,
    clicksReversals: {
      ...state.clicksReversals,
      filter: {
        ...state.clicksReversals.filter,
        clickIds: [],
      },
      sort: sort || state.clicksReversals.sort,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}
export function fetchClickIdsSuccess(state, clickIds) {
  return {
    ...state,
    clicksReversals: {
      ...state.clicksReversals,
      filter: {
        ...state.clicksReversals.filter,
        clickIds,
      },
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}
export function fetchClickIdsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    clicksReversals: {
      ...state.clicksReversals,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function processReversals(state) {
  return {
    ...state,
    clicksReversals: {
      ...state.clicksReversals,
      processing: true,
    },
  };
}
export function processReversalsSuccess(state, result) {
  return {
    ...state,
    clicksReversals: {
      ...state.clicksReversals,
      result,
      processing: false,
    },
  };
}
export function processReversalsFail(state, error) {
  let errorMessage = 'Request failed. Open the browser console for details.';
  if (error.message) {
    errorMessage = error.message;
  }
  if (typeof error === 'string') {
    errorMessage = error;
  }
  return {
    ...state,
    clicksReversals: {
      ...state.clicksReversals,
      isFetching: false,
      processing: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}
