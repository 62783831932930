export function fetchFeasibility(state, { filter }) {
  return {
    ...state,
    feasibility: {
      ...state.feasibility,
      fetchingErrorMessage: '',
      isFetching: true,
      filter,
    },
  };
}

export function fetchFeasibilitySuccess(state, response) {
  return {
    ...state,
    feasibility: {
      ...state.feasibility,
      items: response.items,
      itemsCards: response.itemsCards,
      columns: response.columns,
      columnsCards: response.columnsCards,
      constants: response.constants,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchFeasibilityFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    feasibility: {
      ...state.feasibility,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function changeConstantsUpdateStatus(state, status) {
  return {
    ...state,
    feasibility: {
      ...state.feasibility,
      constantsUpdateStatus: status,
    },
  };
}

export function updateFeasibilityConstantsSuccess(state, { constants }) {
  return {
    ...state,
    feasibility: {
      ...state.feasibility,
      constants,
    },
  };
}

export function updateFeasibilityConstantsFail(state) {
  return state;
}
