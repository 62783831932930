import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as citiesModule from '../modules/cities.module';
import * as Api from '../../core/api';

import { getSort, getFilter } from './selectors/cities';

function* fetchCities({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);

  const { response, error } = yield call(Api.cities.fetchCities, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(citiesModule.fetchCitiesSuccess(response));
  } else {
    yield put(citiesModule.fetchCitiesFail(error));
  }
}

export default [
  takeEvery(citiesModule.fetchCities.getType(), fetchCities),
];
