export function fetchPublishers(state, { page, sort, filter }) {
  return {
    ...state,
    publishers: {
      ...state.publishers,
      currentPage: page,
      sort: sort || state.publishers.sort,
      filter: filter || state.publishers.filter,
    },
  };
}

export function fetchPublishersSuccess(state, publishers) {
  return {
    ...state,
    publishers: {
      ...state.publishers,
      numberOfPages: publishers.numberOfPages,
      count: publishers.count,
      items: publishers.items,
    },
  };
}

export function fetchAllPublishersSuccess(state, publishers) {
  return {
    ...state,
    publishers: {
      ...state.publishers,
      list: publishers,
    },
  };
}

export function fetchPublisherDetailsSuccess(state, publisher) {
  let publishers = state.publishers;
  if (!publishers || publishers.length === 0) {
    publishers = {
      items: [publisher],
      count: 1,
      page: 1,
    };
  }
  return {
    ...state,
    publishers,
    publisher,
  };
}

export function updatePublisherSuccess(state, { publisherId, publisher: publisherData }) {
  const publisherIndex = state.publishers.items.findIndex(x => x._id === publisherId);
  const publisher = state.publishers.items.find(x => x._id === publisherId);

  if (!publisher) {
    return {
      ...state,
      publisher: {
        ...state.publisher,
        postbackUrl: publisherData.postbackUrl,
        successUrl: publisherData.successUrl,
        failureUrl: publisherData.failureUrl,
      },
    };
  }

  return {
    ...state,
    publishers: {
      ...state.publishers,
      items: [
        ...state.publishers.items.slice(0, publisherIndex),
        {
          ...publisher,
          postbackUrl: publisherData.postbackUrl,
          successUrl: publisherData.successUrl,
          failureUrl: publisherData.failureUrl,
        },
        ...state.publishers.items.slice(publisherIndex + 1),
      ],
    },
    publisher: {
      ...state.publisher,
      postbackUrl: publisherData.postbackUrl,
      successUrl: publisherData.successUrl,
      failureUrl: publisherData.failureUrl,
    },
  };
}

export function changePublisherUpdateStatus(state, status) {
  return {
    ...state,
    publisher: {
      ...state.publisher,
      updateStatus: status,
    },
  };
}
