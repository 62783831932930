export const test = {
  currentPage: 1,
  itemsPerPage: 50,
  items: [],
  sort: {
    key: 'revenue',
    order: -1,
  },
  filter: {
    date: {
      from: '',
      to: '',
    },
  },
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 50,
  items: [],
  sort: {
    key: 'revenue',
    order: -1,
  },
  filter: {
    date: {
      from: '',
      to: '',
    },
  },
  isFetching: false,
  fetchingErrorMessage: '',
};
