import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as clicksReversalsModule from '../modules/clicksReversals.module';
import * as Api from '../../core/api';
import { getSort, getFilter } from './selectors/clicksReversals';

function* fetchClicks({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);
  const { response, error } =
    yield call(Api.clicksReversals.fetchClicks, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(clicksReversalsModule.fetchClicksSuccess(response));
  } else {
    yield put(clicksReversalsModule.fetchClicksFail(error));
  }
}

function* fetchClickIds({ payload: { page, itemsPerPage, file } }) {
  const { response, error } =
    yield call(Api.clicksReversals.fetchClickIds, { file });

  if (response) {
    yield put(clicksReversalsModule.fetchClickIdsSuccess(response));
    yield put(clicksReversalsModule.fetchClicks({
      page, itemsPerPage,
    }));
  } else {
    yield put(clicksReversalsModule.fetchClickIdsFail(error));
  }
}

function* processReversals() {
  const filter = yield select(getFilter);
  const { response, error } =
    yield call(Api.clicksReversals.processReversals, { clickIds: filter.clickIds });

  if (response) {
    yield put(clicksReversalsModule.processReversalsSuccess(response));
  } else {
    yield put(clicksReversalsModule.processReversalsFail(error));
  }
}

export default [
  takeEvery(clicksReversalsModule.fetchClicks.getType(), fetchClicks),
  takeEvery(clicksReversalsModule.fetchClickIds.getType(), fetchClickIds),
  takeEvery(clicksReversalsModule.processReversals.getType(), processReversals),
];

