import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as surveysViewModule from '../modules/surveysView.module';
import * as Api from '../../core/api';
import { getSort, getFilter } from './selectors/surveysView';

function* fetchSurveys({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);
  const { response, error } =
    yield call(Api.surveysView.fetchSurveys, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(surveysViewModule.fetchSurveysSuccess(response));
  } else {
    yield put(surveysViewModule.fetchSurveysFail(error));
  }
}

export default [
  takeEvery(surveysViewModule.fetchSurveys.getType(), fetchSurveys),
];
