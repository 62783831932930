import _ from 'lodash';

export function fetchMappings(state, { page, filter, provider }) {
  return {
    ...state,
    mappings: {
      ...state.mappings,
      provider: provider || state.mappings.provider,
      filter: filter || state.mappings.filter,
      currentPage: page,
      fetchingErrorMessage: '',
      needToFetchAfterSaving: false,
      newQuestionAdded: {
        ...state.mappings.newQuestionAdded,
        state: false,
      },
      isFetching: true,
    },
  };
}

export function fetchMappingsSuccess(state, { data, mappings }) {
  return {
    ...state,
    mappings: {
      ...state.mappings,
      numberOfPages: data.numberOfPages,
      count: data.count,
      items: data.items,
      results: mappings,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchMappingsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    mappings: {
      ...state.mappings,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function saveMappings(state) {
  return {
    ...state,
    mappingsDetails: {
      ...state.mappingsDetails,
      saving: 'pending',
    },
  };
}

export function saveMappingsSuccess(state) {
  return {
    ...state,
    mappings: {
      ...state.mappings,
      needToFetchAfterSaving: true,
    },
    mappingsDetails: {
      ...state.mappingsDetails,
      saving: 'success',
    },
  };
}

export function saveMappingsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    mappings: {
      ...state.mappings,
      isFetching: false,
      needToFetchAfterSaving: false,
      fetchingErrorMessage: errorMessage,
    },
    mappingsDetails: {
      ...state.mappingsDetails,
      saving: 'error',
    },
  };
}

export function addAnotherQuestion(state) {
  return {
    ...state,
    mappings: {
      ...state.mappings,
      isFetching: true,
    },
  };
}

export function addAnotherQuestionSuccess(state, response) {
  return {
    ...state,
    mappings: {
      ...state.mappings,
      newQuestionAdded: {
        state: true,
        payload: { ...response },
      },
      isFetching: false,
    },
  };
}

export function addAnotherQuestionFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    mappings: {
      ...state.mappings,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function removeQuestionMapping(state, { questionId, provider, country, index }) {
  return {
    ...state,
    mappings: {
      ...state.mappings,
      isFetching: true,
      newQuestionAdded: {
        ...state.mappings.newQuestionAdded,
        state: false,
      },
    },
  };
}

export function removeMappingSuccess(state) {
  return {
    ...state,
    mappings: {
      ...state.mappings,
      isFetching: false,
    },
  };
}

export function removeMappingFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    mappings: {
      ...state.mappings,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function changeMappingStatus(state) {
  return {
    ...state,
    mappings: {
      ...state.mappings,
      isFetching: true,
    },
  };
}

export function changeMappingStatusSuccess(state) {
  return {
    ...state,
    mappings: {
      ...state.mappings,
      isFetching: false,
      needToFetchAfterSaving: true,
    },
  };
}

export function changeMappingStatusFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    mappings: {
      ...state.mappings,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function fetchMappingsDetails(state) {
  return {
    ...state,
    mappingsDetails: {
      ...state.mappingsDetails,
      isFetching: true,
    },
  };
}

export function fetchMappingsDetailsSuccess(state, mappingsDetails) {
  return {
    ...state,
    mappingsDetails: {
      ...state.mappingsDetails,
      ...mappingsDetails,
      isFetching: false,
    },
  };
}

export function fetchQuestions(state) {
  return {
    ...state,
    mappingsDetails: {
      ...state.mappingsDetails,
      fetchingErrorMessage: '',
      areQuestionsFetching: true,
    },
  };
}

export function fetchQuestionsSuccess(state, questions) {
  return {
    ...state,
    mappingsDetails: {
      ...state.mappingsDetails,
      questions,
      fetchingErrorMessage: '',
      areQuestionsFetching: false,
    },
  };
}

export function fetchQuestionsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    mappingsDetails: {
      ...state.mappingsDetails,
      fetchingErrorMessage: errorMessage,
      areQuestionsFetching: false,
    },
  };
}

export function changeQuestionText(state) {
  return {
    ...state,
    mappingsDetails: {
      ...state.mappingsDetails,
      fetchingErrorMessage: '',
      areQuestionsFetching: true,
    },
  };
}

export function changeQuestionTextSuccess(state, question) {
  const index = _.get(state, 'mappingsDetails.questions', [])
    .findIndex(item => item.questionId === question.questionId);
  let questions = [];
  if (index > -1) {
    questions = [
      ...state.mappingsDetails.questions.slice(0, index),
      {
        ...state.mappingsDetails.questions[index],
        text: question.text,
      },
      ...state.mappingsDetails.questions.slice(index + 1),
    ];
  }
  return {
    ...state,
    mappingsDetails: {
      ...state.mappingsDetails,
      questions,
      fetchingErrorMessage: '',
      areQuestionsFetching: false,
    },
  };
}

export function changeQuestionTextFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    mappingsDetails: {
      ...state.mappingsDetails,
      fetchingErrorMessage: errorMessage,
      areQuestionsFetching: false,
    },
  };
}

export function openMappingsDetailsModal(state, { actionPayload }) {
  return {
    ...state,
    mappingsDetails: {
      ...state.mappingsDetails,
      modal: {
        showModal: true,
        actionPayload,
      },
    },
  };
}

export function closeMappingsDetailsModal(state) {
  return {
    ...state,
    mappingsDetails: {
      ...state.mappingsDetails,
      data: { options: [] },
      saving: '',
      modal: {
        showModal: false,
        actionPayload: {},
      },
    },
  };
}
