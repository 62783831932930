export function changeCountryLanguage(state, { countryLanguageCode }) {
  return {
    ...state,
    usersByAnswers: {
      ...state.usersByAnswers,
      items: [],
      countryLanguageCode,
    },
  };
}

export function fetchUsers(state, { page, questions }) {
  return {
    ...state,
    usersByAnswers: {
      ...state.usersByAnswers,
      currentPage: page,
      questions: questions || state.usersByAnswers.questions,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchUsersSuccess(state, users) {
  return {
    ...state,
    usersByAnswers: {
      ...state.usersByAnswers,
      numberOfPages: users.numberOfPages,
      count: users.count,
      fullMatchCount: users.fullMatchCount,
      wouldMatchCount: users.wouldMatchCount,
      items: users.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchUsersFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    usersByAnswers: {
      ...state.usersByAnswers,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}
