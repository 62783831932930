import { all } from 'redux-saga/effects';

import managersSaga from './managers.saga';
import watchFormSubmitSaga from './onSubmitActions.saga';
import watchAuth from './auth.saga';
import watchMappings from './mappings.saga';
import watchPublishers from './publishers.saga';
import watchUsers from './users.saga';
import watchSurveys from './surveys.saga';
import watchQuestions from './questions.saga';
import watchTopQuestions from './topQuestions.saga';
import watchClicks from './clicks.saga';
import watchApps from './apps.saga';
import watchExtraAnswersReports from './extraAnswersReports.saga';
import watchSaveLeadLogs from './saveLeadLogs.saga';
import watchClicksReports from './clicksReports.saga';
import watchDesktopMobileSurveys from './desktopMobileSurveys.saga';
import watchCountryLanguages from './countryLanguages.saga';
import watchUsersByAnswers from './usersByAnswers.saga';
import watchSurveysRanking from './surveysRanking.saga';
import watchCities from './cities.saga';
import watchAllocations from './allocations.saga';
import watchCronLogs from './cronLogs.saga';
import watchClicksReversals from './clicksReversals.saga';
import watchRankAdjustments from './rankAdjustments.saga';
import watchGlobalSettings from './globalSettings.saga';
import watchTopSurveys from './topSurveys.saga';
import watchPayments from './payments.saga';
import surveyQualificationsLogs from './surveyQualificationsLogs.saga';
import surveysView from './surveysView.saga';
import feasibility from './feasibility.saga';
import reconciliationsLogs from './reconciliationsLogs.saga';

export default function* root() {
  yield all([
    ...managersSaga,
    ...watchFormSubmitSaga,
    ...watchAuth,
    ...watchMappings,
    ...watchPublishers,
    ...watchUsers,
    ...watchSurveys,
    ...watchQuestions,
    ...watchTopQuestions,
    ...watchClicks,
    ...watchApps,
    ...watchExtraAnswersReports,
    ...watchSaveLeadLogs,
    ...watchClicksReports,
    ...watchDesktopMobileSurveys,
    ...watchCountryLanguages,
    ...watchUsersByAnswers,
    ...watchSurveysRanking,
    ...watchCities,
    ...watchAllocations,
    ...watchCronLogs,
    ...watchClicksReversals,
    ...watchRankAdjustments,
    ...watchGlobalSettings,
    ...watchTopSurveys,
    ...watchPayments,
    ...surveyQualificationsLogs,
    ...surveysView,
    ...feasibility,
    ...reconciliationsLogs,
  ]);
}
