import moment from 'moment-timezone';

export const emptyFilter = {
  clickId: '',
  userId: '',
  userCountryCode: '',
  userPlatform: '',
  endpointId: '',
  subProvider: '',
  surveyId: '',
  providerSurveyId: '',
  reversalActionResult: '',
  date: {
    from: moment.tz('America/Los_Angeles').startOf('day').format('MM/DD/YYYY'),
    to: moment.tz('America/Los_Angeles').startOf('day').format('MM/DD/YYYY'),
  },
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 30,
  items: [],
  sort: {
    key: 'reversalDate',
    order: -1,
  },
  filter: emptyFilter,
  isFetching: false,
  fetchingErrorMessage: '',
};

export const test = {
  currentPage: 1,
  itemsPerPage: 30,
  items: [],
  sort: {
    key: 'reversalDate',
    order: -1,
  },
  filter: emptyFilter,
  isFetching: false,
  fetchingErrorMessage: '',
};

