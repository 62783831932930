import React  from 'react';
import PropTypes from 'prop-types';
import InputError from './InputError.jsx';

export default function InputWithError({ meta, input, ...props }) {
  return (
    <div>
      <input {...input} {...props} />
      <div className="error-line">
        <InputError touched={meta.touched} error={meta.error} />
      </div>
    </div>
  );
}

InputWithError.propTypes = {
  touched: PropTypes.bool,
  error: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  input: PropTypes.object,
};
