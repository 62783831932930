import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { login, loginSuccess, loginFail } from '../../redux/modules/auth.module';
import { onSubmitActions } from '../../redux/saga/onSubmitActions.saga';

import Login from './Login.jsx';

export function mapStateToProps() {
  return {
  };
}

export const mapDispatchToProps = {
  login,
};

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email is required';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  }

  return errors;
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'loginForm',
  validate,
  onSubmit: onSubmitActions(login, loginSuccess, loginFail),
})(Login));
