import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/index/App.auth.jsx';
import store from './redux/store';

require('../scss/app.scss'); // eslint-disable-line global-require
require('../scss/login.scss'); // eslint-disable-line global-require

createRoot(document.getElementById('react-app'))
  .render(<App store={store} />);

