export function fetchCronLogs(state, { page, sort, filter }) {
  return {
    ...state,
    cronLogs: {
      ...state.cronLogs,
      currentPage: page,
      sort: sort || state.cronLogs.sort,
      filter: filter || state.cronLogs.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchCronLogsSuccess(state, cronLogs) {
  return {
    ...state,
    cronLogs: {
      ...state.cronLogs,
      numberOfPages: cronLogs.numberOfPages,
      count: cronLogs.count,
      items: cronLogs.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchCronLogsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    cronLogs: {
      ...state.cronLogs,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}
