import { createAction } from 'redux-act';

import * as apps from '../../core/apps';


export const fetchApps = createAction(
  'get all apps',
);
export const fetchAppsSuccess = createAction(
  'get all apps success',
  (allApps) => allApps
);
export const fetchAppsFail = createAction(
  'get all apps fail',
  (error) => error
);

export const openUpdateApp = createAction(
  'open update app modal'
);

export const closeUpdateApp = createAction(
  'close update app modal'
);

export const updateApp = createAction(
  'update app'
);

export const updateAppSuccess = createAction(
  'update app success'
);

export const updateAppFail = createAction(
  'update app fail',
  (error) => error
);

export const addApp = createAction(
  'add new app',
);
export const addAppSuccess = createAction(
  'add new app success',
  (app) => app
);
export const addAppFail = createAction(
  'add new app fail',
  (error) => error
);

export const reducer = {
  [fetchApps]: (state, payload) =>
    apps.fetchApps(state, payload),
  [fetchAppsSuccess]: (state, payload) =>
    apps.fetchAppsSuccess(state, payload),
  [fetchAppsFail]: (state, payload) =>
    apps.fetchAppsFail(state, payload),

  [addAppSuccess]: (state, payload) =>
    apps.addAppSuccess(state, payload),

  [openUpdateApp]: (state, payload) =>
    apps.openUpdateApp(state, payload),
  [closeUpdateApp]: (state, payload) =>
    apps.closeUpdateApp(state, payload),
  [updateAppSuccess]: (state, payload) =>
    apps.updateAppSuccess(state, payload),
};
