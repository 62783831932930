import { takeEvery, put, call, fork, select } from 'redux-saga/effects';

import * as publishersModule from '../modules/publishers.module';
import * as Api from '../../core/api';
import { getSort, getFilter } from './selectors/publishers';

export function* fetchPublishers({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);
  const { response, error } =
    yield call(Api.publishers.fetchPublishers, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(publishersModule.fetchPublishersSuccess(response));
  } else {
    yield put(publishersModule.fetchPublishersFail(error));
  }
}

export function* fetchAllPublishers({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);
  const { response, error } =
    yield call(Api.publishers.fetchAllPublishers, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(publishersModule.fetchAllPublishersSuccess(response));
  } else {
    yield put(publishersModule.fetchAllPublishersFail(error));
  }
}

export function* fetchPublisherDetails({ payload: { publisherId } }) {
  const { response, error } = yield call(Api.publishers.fetchPublisherDetails, publisherId);

  if (response) {
    yield put(publishersModule.fetchPublisherDetailsSuccess(response));
  } else {
    yield put(publishersModule.fetchPublisherDetailsFail(error));
  }
}

export function* updatePublisher({ payload: { publisherId, publisher } }) {
  const { response, error } =
    yield call(Api.publishers.updatePublisher, { publisherId, publisher });

  if (response) {
    yield put(publishersModule.updatePublisherSuccess({ publisherId, publisher }));
    yield put(publishersModule.changePublisherUpdateStatus('Success'));
  } else {
    yield put(publishersModule.updatePublisherFail(error));
    yield put(publishersModule.changePublisherUpdateStatus('Failed'));
  }
}


export default [
  takeEvery(publishersModule.fetchPublishers.getType(), fetchPublishers),
  takeEvery(publishersModule.fetchAllPublishers.getType(), fetchAllPublishers),
  takeEvery(publishersModule.fetchPublisherDetails.getType(), fetchPublisherDetails),
  takeEvery(publishersModule.updatePublisher.getType(), updatePublisher),
];
