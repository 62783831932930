export function fetchSaveLeadLogs(state, { page, sort, filter }) {
  return {
    ...state,
    saveLeadLogs: {
      ...state.saveLeadLogs,
      currentPage: page,
      sort: sort || state.saveLeadLogs.sort,
      filter: filter || state.saveLeadLogs.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchSaveLeadLogsSuccess(state, saveLeadLogs) {
  return {
    ...state,
    saveLeadLogs: {
      ...state.saveLeadLogs,
      numberOfPages: saveLeadLogs.numberOfPages,
      count: saveLeadLogs.count,
      items: saveLeadLogs.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchSaveLeadLogsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    saveLeadLogs: {
      ...state.saveLeadLogs,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}
