export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'period',
    order: -1,
  },
  filter: {
    period: '',
    provider: '',
  },
  changePaymentModal: {
    showModal: false,
    errorMessage: '',
    payment: {},
  },
  fetchingErrorMessage: '',
  isFetching: false,
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'period',
    order: -1,
  },
  filter: {
    period: '',
    provider: '',
  },
  changePaymentModal: {
    showModal: false,
    errorMessage: '',
    payment: {},
  },
  fetchingErrorMessage: '',
  isFetching: false,
};
