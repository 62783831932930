export function fetchPayments(state, { page, sort, filter }) {
  return {
    ...state,
    payments: {
      ...state.payments,
      currentPage: page,
      sort: sort || state.payments.sort,
      filter: filter || state.payments.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchPaymentsSuccess(state, payments) {
  return {
    ...state,
    payments: {
      ...state.payments,
      numberOfPages: payments.numberOfPages,
      count: payments.count,
      items: payments.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchPaymentsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    payments: {
      ...state.payments,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function openChangeProviderPaymentModal(state, payment) {
  return {
    ...state,
    payments: {
      ...state.payments,
      changePaymentModal: {
        ...state.payments.changePaymentModal,
        showModal: true,
        payment: payment || {},
      },
    },
  };
}

export function closeChangeProviderPaymentModal(state) {
  return {
    ...state,
    payments: {
      ...state.payments,
      changePaymentModal: {
        showModal: false,
        payment: {},
      },
    },
  };
}

export function changeProviderPaymentSuccess(state, updatedPayment) {
  const oldPaymentIndex = state.payments.items.findIndex(x => x._id === updatedPayment._id);

  if (oldPaymentIndex === -1) {
    return {
      ...state,
      payments: {
        ...state.payments,
        changePaymentModal: {
          showModal: false,
          errorMessage: '',
          payment: {},
        },
      },
    };
  }

  const oldPayment = state.payments.items[oldPaymentIndex];

  return {
    ...state,
    payments: {
      ...state.payments,
      items: [
        ...state.payments.items.slice(0, oldPaymentIndex),
        {
          ...oldPayment,
          providerPaymentValue: updatedPayment.providerPaymentValue,
        },
        ...state.payments.items.slice(oldPaymentIndex + 1),
      ],
      changePaymentModal: {
        showModal: false,
        errorMessage: '',
        payment: {},
      },
    },
  };
}

export function changeProviderPaymentFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    payments: {
      ...state.payments,
      changePaymentModal: {
        ...state.payments.changePaymentModal,
        errorMessage,
      },
    },
  };
}
