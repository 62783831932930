export function fetchExtraAnswersReports(state, { page, filter }) {
  return {
    ...state,
    extraAnswersReports: {
      ...state.extraAnswersReports,
      currentPage: page,
      filter: filter || state.extraAnswersReports.filter,
      activeFetchingCounter: state.extraAnswersReports.activeFetchingCounter + 1,
      fetchingErrorMessages: [],
      isFetching: true,
    },
  };
}

export function fetchExtraAnswersReportsSuccess(state, extraAnswers) {
  const fetchingCounter = state.extraAnswersReports.activeFetchingCounter - 1;
  const [extraAnswersByUsers, extraAnswersByCountry] = extraAnswers;
  return {
    ...state,
    extraAnswersReports: {
      ...state.extraAnswersReports,
      items: extraAnswersByUsers.items,
      numberOfPages: extraAnswersByUsers.numberOfPages,
      count: extraAnswersByUsers.count,
      itemsByCountry: extraAnswersByCountry,
      activeFetchingCounter: fetchingCounter,
      isFetching: !(fetchingCounter === 0),
    },
  };
}

export function fetchExtraAnswersReportsFail(state, error) {
  const fetchingCounter = state.extraAnswersReports.activeFetchingCounter - 1;
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    extraAnswersReports: {
      ...state.extraAnswersReports,
      activeFetchingCounter: fetchingCounter,
      isFetching: !(fetchingCounter === 0),
      fetchingErrorMessages: [...state.extraAnswersReports.fetchingErrorMessages, errorMessage],
    },
  };
}
