export const test = {
  filter: {
    countryCode: '',
  },
  items: [],
  columns: [],
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  items: [],
  itemsCards: [],
  columns: [],
  columnsCards: [],
  constants: {},
  filter: {
    countryCode: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};
