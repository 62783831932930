import { createAction } from 'redux-act';

import * as cities from '../../core/cities';

export const fetchCities = createAction(
  'get all cities',
);
export const fetchCitiesSuccess = createAction(
  'get all cities success',
  (allCities) => allCities
);
export const fetchCitiesFail = createAction(
  'get all cities fail',
  (error) => error
);

export const reducer = {
  [fetchCities]: (state, payload) =>
    cities.fetchCities(state, payload),
  [fetchCitiesSuccess]: (state, payload) =>
    cities.fetchCitiesSuccess(state, payload),
  [fetchCitiesFail]: (state, payload) =>
    cities.fetchCitiesFail(state, payload),
};
