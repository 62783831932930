import { apiRequest, postApiRequest } from './_tools';
import { range } from 'lodash';

export function fetchUsers({ page, itemsPerPage, sort, filter }) {
  const userId = encodeURIComponent(filter.userId);
  const _id = encodeURIComponent(filter._id);
  const appId = encodeURIComponent(filter.appId);
  const publisherId = encodeURIComponent(filter.publisherId);
  return apiRequest(`/api/usersWithPaging?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&_id=${_id}` +
    `&userId=${userId}` +
    `&appId=${appId}` +
    `&publisherId=${publisherId}` +
    `&verifiedPhoneNumber=${filter.verifiedPhoneNumber}` +
    `&verifiedPhoneNumberExists=${filter.verifiedPhoneNumberExists}` +
    `&countryName=${filter.countryName}` +
    `&banned=${filter.banned}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
    .catch(({ error }) => Promise.reject(error));
}

export function fetchUsersByAnswers({ page, itemsPerPage, countryLanguageCode, questions }) {
  const questionsToFilter = questions.map(q => {
    const question = {
      questionId: q.questionId,
      answers: q.answers.constructor === String ? [q.answers] : q.answers,
      // isRequired: q.isRequired,
    };

    if (question.questionId === 42) {
      const answers = question.answers[0].split('-').map(a => +a);
      if (answers.length === 2) {
        question.answers = range(answers[0], answers[1] + 1).map((v) => `${v}`);
      }
    }

    return question;
  });

  const body = {
    page,
    itemsPerPage: itemsPerPage || 10,
    countryLanguageCode,
    questions: questionsToFilter,
  };

  return postApiRequest('/api/usersWithPaging', body)
    .catch(({ error }) => Promise.reject(error));
}

export function fetchUserDetails(userId) {
  return apiRequest(`/api/usersWithPaging/${userId}/targets`)
    .catch(({ error }) => Promise.reject(error));
}

export function fetchUserWithTargetedSurveys({ userId, page, itemsPerPage }) {
  return apiRequest(`/api/usersWithPaging/${userId}/targets` +
    `?page=${page}` +
    `&itemsPerPage=${itemsPerPage}`)
    .catch(({ error }) => Promise.reject(error));
}

export function fetchUsersWithClickedGroups({ page, itemsPerPage, filter }) {
  return apiRequest(`/api/usersWithClickedGroups?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&_id=${filter._id}` +
    `&surveyGroupId=${filter.surveyGroupId}` +
    `&userId=${filter.userId}`)
    .catch(({ error }) => Promise.reject(error));
}

export function fetchUsersWithReversals({ page, itemsPerPage, filter, sort }) {
  const userId = encodeURIComponent(filter.userId);
  return apiRequest(`/api/usersWithReversals?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&_id=${filter._id}` +
    `&userId=${userId}` +
    `&countryName=${filter.countryName}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
    .catch(({ error }) => Promise.reject(error));
}
