import { takeEvery, put, call } from 'redux-saga/effects';

import * as authModule from '../modules/auth.module';
import * as Api from '../../core/api';

export function* performLogin({ payload: { email, password } }) {
  const { response, error } = yield call(Api.auth.login, { email, password });

  if (response) {
    yield put(authModule.loginSuccess(response));
  } else {
    yield put(authModule.loginFail(error));
  }
}

export default [
  takeEvery(authModule.login.getType(), performLogin),
];
