import * as authModule from '../modules/auth.module';

export default () => next => action => {
  if (action.type === authModule.loginSuccess().type) {
    const url = action.payload.redirectUrl;
    window.location = url;
    return undefined;
  }

  return next(action);
};
