export function fetchAllocations(state, { page, sort, filter }) {
  return {
    ...state,
    allocations: {
      ...state.allocations,
      currentPage: page,
      sort: sort || state.allocations.sort,
      filter: filter || state.allocations.filter,
    },
  };
}

export function fetchAllocationsSuccess(state, allocations) {
  return {
    ...state,
    allocations: {
      ...state.allocations,
      numberOfPages: allocations.numberOfPages,
      count: allocations.count,
      items: allocations.items,
    },
  };
}

export function addAllocationSuccess(state, allocation) {
  return {
    ...state,
    allocations: {
      ...state.allocations,
      items: [
        ...state.allocations.items,
        allocation,
      ],
    },
  };
}

export function openUpdateAllocation(state, { allocation }) {
  return {
    ...state,
    editAllocation: {
      showModal: true,
      allocation,
    },
  };
}

export function closeUpdateAllocation(state) {
  return {
    ...state,
    editAllocation: {
      showModal: false,
    },
  };
}

export function updateAllocationSuccess(state, { allocationId, allocation: allocationData }) {
  const allocationIndex = state.allocations.items.findIndex(x => x._id === allocationId);
  const allocation = state.allocations.items.find(x => x._id === allocationId);
  if (!allocation) {
    return state;
  }
  return {
    ...state,
    allocations: {
      ...state.allocations,
      items: [
        ...state.allocations.items.slice(0, allocationIndex),
        {
          ...allocation,
          key: allocationData.key,
          value: allocationData.value,
        },
        ...state.allocations.items.slice(allocationIndex + 1),
      ],
    },
  };
}

export function deleteAllocationSuccess(state, { allocationId }) {
  const allocationIndex = state.allocations.items.findIndex(x => x._id === allocationId);
  const allocation = state.allocations.items.find(x => x._id === allocationId);
  if (!allocation) {
    return state;
  }
  return {
    ...state,
    allocations: {
      ...state.allocations,
      items: [
        ...state.allocations.items.slice(0, allocationIndex),
        ...state.allocations.items.slice(allocationIndex + 1),
      ],
    },
  };
}
