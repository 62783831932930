export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'clickDate',
    order: -1,
  },
  filter: {
    clickIds: [],
    id: '',
    clickId: '',
    internalUserId: '',
    userId: '',
    userCountryCode: '',
    appId: '',
    surveyId: '',
    surveyUniqueNumber: '',
    surveyProvider: '',
    clickDate: {
      from: '',
      to: '',
    },
    ip: '',
    isMobile: '',
    leadDate: {
      from: '',
      to: '',
    },
    success: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'clickDate',
    order: -1,
  },
  filter: {
    clickIds: [],
    id: '',
    clickId: '',
    internalUserId: '',
    userId: '',
    userCountryCode: '',
    appId: '',
    surveyId: '',
    surveyUniqueNumber: '',
    surveyProvider: '',
    clickDate: {
      from: '',
      to: '',
    },
    ip: '',
    isMobile: '',
    leadDate: {
      from: '',
      to: '',
    },
    success: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};
