export function fetchSurveyQualificationsLogs(state, { page, sort, filter }) {
  return {
    ...state,
    surveyQualificationsLogs: {
      ...state.surveyQualificationsLogs,
      currentPage: page,
      sort: sort || state.surveyQualificationsLogs.sort,
      filter: filter || state.surveyQualificationsLogs.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchSurveyQualificationsLogsSuccess(state, surveyQualificationsLogs) {
  return {
    ...state,
    surveyQualificationsLogs: {
      ...state.surveyQualificationsLogs,
      numberOfPages: surveyQualificationsLogs.numberOfPages,
      count: surveyQualificationsLogs.count,
      items: surveyQualificationsLogs.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchSurveyQualificationsLogsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    surveyQualificationsLogs: {
      ...state.surveyQualificationsLogs,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function openSurveyQualificationsModal(state, { qualifications }) {
  return {
    ...state,
    surveyQualificationsLogs: {
      ...state.surveyQualificationsLogs,
      showModal: true,
      modalQualifications: qualifications,
    },
  };
}

export function closeSurveyQualificationsModal(state) {
  return {
    ...state,
    surveyQualificationsLogs: {
      ...state.surveyQualificationsLogs,
      showModal: false,
    },
  };
}
