export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  countryLanguageCode: '',
  fullMatchCount: {},
  wouldMatchCount: {},
  questions: [],
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  countryLanguageCode: '',
  fullMatchCount: {},
  wouldMatchCount: {},
  questions: [],
  isFetching: false,
  fetchingErrorMessage: '',
};
