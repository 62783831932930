import { createAction } from 'redux-act';

import * as allocations from '../../core/allocations';


export const fetchAllocations = createAction(
  'get all allocations',
);
export const fetchAllocationsSuccess = createAction(
  'get all allocations success',
  (allAllocations) => allAllocations
);
export const fetchAllocationsFail = createAction(
  'get all allocations fail',
  (error) => error
);

export const openUpdateAllocation = createAction(
  'open update allocation modal'
);

export const closeUpdateAllocation = createAction(
  'close update allocation modal'
);

export const updateAllocation = createAction(
  'update allocation'
);
export const updateAllocationSuccess = createAction(
  'update allocation success'
);
export const updateAllocationFail = createAction(
  'update allocation fail',
  (error) => error
);

export const deleteAllocation = createAction(
  'delete allocation'
);
export const deleteAllocationSuccess = createAction(
  'delete allocation success'
);
export const deleteAllocationFail = createAction(
  'delete allocation fail',
  (error) => error
);

export const addAllocation = createAction(
  'add new allocation',
);
export const addAllocationSuccess = createAction(
  'add new allocation success',
  (allocation) => allocation
);
export const addAllocationFail = createAction(
  'add new allocation fail',
  (error) => error
);

export const reducer = {
  [fetchAllocations]: (state, payload) =>
    allocations.fetchAllocations(state, payload),
  [fetchAllocationsSuccess]: (state, payload) =>
    allocations.fetchAllocationsSuccess(state, payload),

  [addAllocationSuccess]: (state, payload) =>
    allocations.addAllocationSuccess(state, payload),

  [openUpdateAllocation]: (state, payload) =>
    allocations.openUpdateAllocation(state, payload),
  [closeUpdateAllocation]: (state, payload) =>
    allocations.closeUpdateAllocation(state, payload),
  [updateAllocationSuccess]: (state, payload) =>
    allocations.updateAllocationSuccess(state, payload),
  [deleteAllocationSuccess]: (state, payload) =>
    allocations.deleteAllocationSuccess(state, payload),
};
