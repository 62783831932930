import { apiRequest, postApiRequest } from './_tools';

export function fetchQuestions({ page, itemsPerPage, sort, filter }) {
  const name = encodeURIComponent(filter.name);

  return apiRequest(`/api/questions?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&name=${name}` +
    `&questionId=${filter.questionId}` +
    `&isRedHerring=${filter.isRedHerring}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}` +
    `&countryLanguageCode=${filter.countryLanguageCode}`
  )
    .catch(({ error }) => Promise.reject(error));
}

export function fetchQuestionsByCountryLanguageCode(countryLanguageCode) {
  return apiRequest(`/api/questions/${countryLanguageCode}`)
    .catch(({ error }) => Promise.reject(error));
}

export function fetchQuestionByCountryLanguageCodeAndId({ countryLanguageCode, questionId, skipErrLog }) {
  return apiRequest(`/api/questions/${countryLanguageCode}/${questionId}?skipErrLog=${skipErrLog || false}`)
    .catch(({ error }) => Promise.reject(error));
}

export function updateQuestion(question) {
  return postApiRequest(`/api/questions/byId/${question.questionId}`, question)
    .catch(({ error }) => Promise.reject(error));
}

export function fetchTopQuestions({ page, itemsPerPage, countryLanguageCode, userId }) {
  return apiRequest(`/api/topQuestions?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&countryLanguageCode=${countryLanguageCode}` +
    `&userId=${userId}`)
    .catch(({ error }) => Promise.reject(error));
}

export function fetchTopPreliminary({ filter }) {
  return apiRequest('/api/topPreliminary' +
    `?dateFrom=${filter.date.from}` +
    `&dateTo=${filter.date.to}` +
    `&countryCode=${filter.countryCode}`)
    .catch(({ error }) => Promise.reject(error));
}
