export function fetchReconciliationsLogs(state, { page, sort, filter }) {
  return {
    ...state,
    reconciliationsLogs: {
      ...state.reconciliationsLogs,
      currentPage: page,
      sort: sort || state.reconciliationsLogs.sort,
      filter: filter || state.reconciliationsLogs.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchReconciliationsLogsSuccess(state, reconciliationsLogs) {
  return {
    ...state,
    reconciliationsLogs: {
      ...state.reconciliationsLogs,
      numberOfPages: reconciliationsLogs.numberOfPages,
      count: reconciliationsLogs.count,
      items: reconciliationsLogs.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchReconciliationsLogsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    reconciliationsLogs: {
      ...state.reconciliationsLogs,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

