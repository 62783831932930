export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  count: 0,
  sort: {
    key: 'position',
    order: -1,
  },
  filter: {
    rankType: '',
    internalOrExternalUserId: '',
    targetingView: false,
    mobileResponsive: false,
    numberOfAdditionalQuestions: -1,
    provider: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'position',
    order: -1,
  },
  count: 0,
  filter: {
    rankType: '',
    internalOrExternalUserId: '',
    targetingView: false,
    mobileResponsive: false,
    numberOfAdditionalQuestions: -1,
    provider: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};
