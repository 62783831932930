export function fetchSurveys(state, { page, sort, filter }) {
  return {
    ...state,
    topSurveys: {
      ...state.topSurveys,
      currentPage: page,
      sort: sort || state.topSurveys.sort,
      filter: filter || state.topSurveys.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchSurveysSuccess(state, surveys) {
  return {
    ...state,
    topSurveys: {
      ...state.topSurveys,
      count: surveys.length,
      items: surveys,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchSurveysFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    topSurveys: {
      ...state.topSurveys,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}
