export function changeCountryLanguageCode(state, { countryLanguageCode }) {
  return {
    ...state,
    topQuestions: {
      ...state.topQuestions,
      items: [],
      countryLanguageCode,
    },
  };
}

export function changeUserId(state, { userId }) {
  return {
    ...state,
    topQuestions: {
      ...state.topQuestions,
      items: [],
      userId,
    },
  };
}

export function fetchTopQuestions(state, { page }) {
  return {
    ...state,
    topQuestions: {
      ...state.topQuestions,
      currentPage: page,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchTopQuestionsSuccess(state, topQuestions) {
  return {
    ...state,
    topQuestions: {
      ...state.topQuestions,
      numberOfPages: topQuestions.numberOfPages,
      count: topQuestions.count,
      items: topQuestions.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchTopQuestionsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    topQuestions: {
      ...state.topQuestions,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function fetchTopPreliminary(state, { filter }) {
  return {
    ...state,
    topPreliminary: {
      ...state.topPreliminary,
      fetchingErrorMessage: '',
      isFetching: true,
      filter,
    },
  };
}

export function fetchTopPreliminarySuccess(state, topPreliminary) {
  return {
    ...state,
    topPreliminary: {
      ...state.topPreliminary,
      items: topPreliminary,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchTopPreliminaryFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    topPreliminary: {
      ...state.topPreliminary,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

