import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as surveysRankingModule from '../modules/surveysRanking.module';
import * as Api from '../../core/api';
import { getSort, getFilter } from './selectors/surveysRanking';

function* fetchSurveys({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);
  const { response, error } =
    yield call(Api.surveysRanking.fetchSurveys, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(surveysRankingModule.fetchSurveysSuccess(response));
  } else {
    yield put(surveysRankingModule.fetchSurveysFail(error));
  }
}

function* changeSurveyRank({ payload: { rankValue, surveyId } }) {
  const { response, error } =
    yield call(Api.surveysRanking.changeSurveyRank, { rankValue, surveyId });

  if (response) {
    yield put(surveysRankingModule.changeSurveyRankSuccess({
      _id: surveyId,
      isManualRank: rankValue !== '',
      rankValue: +rankValue,
    }));
  } else {
    yield put(surveysRankingModule.changeSurveyRankFail(error));
  }
}

export default [
  takeEvery(surveysRankingModule.fetchSurveys.getType(), fetchSurveys),
  takeEvery(surveysRankingModule.changeSurveyRank.getType(), changeSurveyRank),
];
