export function fetchManagers(state, { page, sort }) {
  return {
    ...state,
    managers: {
      ...state.managers,
      currentPage: page,
      sort: sort || state.managers.sort,
    },
  };
}

export function fetchManagersSuccess(state, managers) {
  return {
    ...state,
    managers: {
      ...state.managers,
      numberOfPages: managers.numberOfPages,
      count: managers.count,
      items: managers.items,
    },
  };
}

export function openAddManagerModal(state) {
  return {
    ...state,
    newManager: {
      showModal: true,
    },
  };
}

export function closeAddManagerModal(state) {
  return {
    ...state,
    newManager: {
      showModal: false,
    },
  };
}

export function addManagerSuccess(state, manager) {
  return {
    ...state,
    managers: {
      ...state.managers,
      items: [
        ...state.managers.items,
        manager,
      ],
    },
  };
}

export function addManagerFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';

  return {
    ...state,
    newManager: {
      ...state.newManager,
      addingErrorMessage: errorMessage,
    },
  };
}
