export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  survey: {},
  questionsDetails: [],
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  survey: {},
  questionsDetails: [],
  isFetching: false,
  fetchingErrorMessage: '',
};
