import { apiRequest } from './_tools';

export function fetchSurveys({ page, itemsPerPage, sort, filter }) {
  return apiRequest(`/api/surveysByPages/top?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&dateFrom=${filter.date.from}` +
    `&dateTo=${filter.date.to}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
    .catch(({ error }) => Promise.reject(error));
}
