import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import InputWithError from '../shared/InputWithError.jsx';

export default function Login({
  error, handleSubmit,
}) {
  return (
    <form className="form-signin" onSubmit={handleSubmit}>
      {error ? <div className="alert alert-danger" role="alert">{error}</div> : ''}
      <label className="sr-only">Email address</label>
      <Field
        component={InputWithError}
        type="text"
        name="email"
        className="form-control"
        placeholder="Email address"
      />

      <label className="sr-only">Password</label>
      <Field
        component={InputWithError}
        type="password"
        name="password"
        className="form-control"
        placeholder="Password"
      />

      <button
        className="btn btn-lg btn-primary btn-block"
        type="submit"
      >Sign in
      </button>
    </form>
  );
}

Login.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
};
