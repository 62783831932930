import { createAction } from 'redux-act';

import * as payments from '../../core/payments';

export const fetchPayments = createAction(
  'get all payments',
);
export const fetchPaymentsSuccess = createAction(
  'get all payments success',
  (allPayments) => allPayments
);
export const fetchPaymentsFail = createAction(
  'get all payments fail',
  (error) => error
);

export const openChangeProviderPaymentModal = createAction(
  'open change provider payment modal'
);

export const closeChangeProviderPaymentModal = createAction(
  'close change provider payment modal'
);

export const changeProviderPayment = createAction(
  'change provider payment',
);
export const changeProviderPaymentSuccess = createAction(
  'change provider payment success'
);
export const changeProviderPaymentFail = createAction(
  'change provider payment fail'
);

export const reducer = {
  [fetchPayments]: (state, payload) =>
    payments.fetchPayments(state, payload),
  [fetchPaymentsSuccess]: (state, payload) =>
    payments.fetchPaymentsSuccess(state, payload),
  [fetchPaymentsFail]: (state, payload) =>
    payments.fetchPaymentsFail(state, payload),

  [openChangeProviderPaymentModal]: (state, payload) =>
    payments.openChangeProviderPaymentModal(state, payload),
  [closeChangeProviderPaymentModal]: (state, payload) =>
    payments.closeChangeProviderPaymentModal(state, payload),
  [changeProviderPaymentSuccess]: (state, payload) =>
    payments.changeProviderPaymentSuccess(state, payload),
  [changeProviderPaymentFail]: (state, payload) =>
    payments.changeProviderPaymentFail(state, payload),
};
