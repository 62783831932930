import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as topQuestionsModule from '../modules/topQuestions.module';
import * as Api from '../../core/api';
import { getCountryLanguageCode, getUserId, getPreliminaryFilter } from './selectors/topQuestions';

function* fetchTopQuestions({ payload: { page, itemsPerPage } }) {
  const countryLanguageCode = yield select(getCountryLanguageCode);
  const userId = yield select(getUserId);
  const { response, error } =
    yield call(
      Api.questions.fetchTopQuestions,
      { page, itemsPerPage, countryLanguageCode, userId },
    );

  if (response) {
    yield put(topQuestionsModule.fetchTopQuestionsSuccess(response));
  } else {
    yield put(topQuestionsModule.fetchTopQuestionsFail(error));
  }
}

function* fetchTopPreliminary() {
  const filter = yield select(getPreliminaryFilter);
  const { response, error } =
    yield call(
      Api.questions.fetchTopPreliminary,
      { filter },
    );

  if (response) {
    yield put(topQuestionsModule.fetchTopPreliminarySuccess(response));
  } else {
    yield put(topQuestionsModule.fetchTopPreliminaryFail(error));
  }
}

export default [
  takeEvery(topQuestionsModule.fetchTopQuestions.getType(), fetchTopQuestions),
  takeEvery(topQuestionsModule.fetchTopPreliminary.getType(), fetchTopPreliminary),
];
