import { createAction } from 'redux-act';

import * as questions from '../../core/questions';

export const fetchQuestions = createAction(
  'get all questions',
);
export const fetchQuestionsSuccess = createAction(
  'get all questions success',
  (allQuestions) => allQuestions
);
export const fetchQuestionsFail = createAction(
  'get all questions fail',
  (error) => error
);

export const fetchQuestionsByCountryLanguageCode = createAction(
  'get all questions by country language code',
);
export const fetchQuestionsByCountryLanguageCodeSuccess = createAction(
  'get all questions by country language code success',
  (allQuestions) => allQuestions
);
export const fetchQuestionsByCountryLanguageCodeFail = createAction(
  'get all questions by country language code fail',
  (error) => error
);

export const openEditQuestionModal = createAction(
  'open edit question modal'
);

export const closeEditQuestionModal = createAction(
  'close edit question modal'
);

export const updateQuestion = createAction(
  'update question',
);
export const updateQuestionSuccess = createAction(
  'update question success'
);
export const updateQuestionFail = createAction(
  'update question fail'
);

export const reducer = {
  [fetchQuestions]: (state, payload) =>
    questions.fetchQuestions(state, payload),
  [fetchQuestionsSuccess]: (state, payload) =>
    questions.fetchQuestionsSuccess(state, payload),
  [fetchQuestionsFail]: (state, payload) =>
    questions.fetchQuestionsFail(state, payload),

  [fetchQuestionsByCountryLanguageCodeSuccess]: (state, payload) =>
    questions.fetchQuestionsByCountryLanguageCodeSuccess(state, payload),

  [openEditQuestionModal]: (state, payload) =>
    questions.openEditQuestionModal(state, payload),
  [closeEditQuestionModal]: (state, payload) =>
    questions.closeEditQuestionModal(state, payload),
  [updateQuestionSuccess]: (state, payload) =>
    questions.updateQuestionSuccess(state, payload),
  [updateQuestionFail]: (state, payload) =>
    questions.updateQuestionFail(state, payload),
};
