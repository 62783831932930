import { takeEvery, put, call, select } from 'redux-saga/effects';
import { reset as resetForm } from 'redux-form';

import * as rankAdjustmentsModule from '../modules/rankAdjustments.module';
import * as Api from '../../core/api';

import { getSort, getFilter, getPaging } from './selectors/rankAdjustments';

function* fetchRankAdjustments({ payload: { page, itemsPerPage } }) {
  const sort = yield select(getSort);
  const filter = yield select(getFilter);

  const { response, error } = yield call(Api.rankAdjustments.fetchRankAdjustments, { page, itemsPerPage, sort, filter });

  if (response) {
    yield put(rankAdjustmentsModule.fetchRankAdjustmentsSuccess(response));
  } else {
    yield put(rankAdjustmentsModule.fetchRankAdjustmentsFail(error));
  }
}

function* addRankAdjustment({ payload: rankAdjustment }) {
  const { response, error } = yield call(Api.rankAdjustments.addRankAdjustment, rankAdjustment);
  const paging = yield select(getPaging);

  if (response) {
    yield put(rankAdjustmentsModule.addRankAdjustmentSuccess(response));
    yield put(rankAdjustmentsModule.closeUpdateRankAdjustment());
    yield put(rankAdjustmentsModule.fetchRankAdjustments(paging));
    yield put(resetForm('addRankAdjustment'));
  } else {
    yield put(rankAdjustmentsModule.addRankAdjustmentFail(error));
  }
}

function* updateRankAdjustment({ payload: { rankAdjustmentId, rankAdjustment } }) {
  const { response, error } = yield call(Api.rankAdjustments.updateRankAdjustment, { rankAdjustmentId, rankAdjustment });
  const paging = yield select(getPaging);

  if (response) {
    yield put(rankAdjustmentsModule.updateRankAdjustmentSuccess({ rankAdjustmentId, rankAdjustment }));
    yield put(rankAdjustmentsModule.closeUpdateRankAdjustment());
    yield put(rankAdjustmentsModule.fetchRankAdjustments(paging));
    yield put(resetForm('addRankAdjustment'));
  } else {
    yield put(rankAdjustmentsModule.updateRankAdjustmentFail(error));
  }
}

function* deleteRankAdjustment({ payload: { rankAdjustmentId } }) {
  const { response, error } = yield call(Api.rankAdjustments.deleteRankAdjustment, { rankAdjustmentId });

  if (response) {
    yield put(rankAdjustmentsModule.deleteRankAdjustmentSuccess({ rankAdjustmentId }));
  } else {
    yield put(rankAdjustmentsModule.deleteRankAdjustmentFail(error));
  }
}

export default [
  takeEvery(rankAdjustmentsModule.fetchRankAdjustments.getType(), fetchRankAdjustments),
  takeEvery(rankAdjustmentsModule.addRankAdjustment.getType(), addRankAdjustment),
  takeEvery(rankAdjustmentsModule.updateRankAdjustment.getType(), updateRankAdjustment),
  takeEvery(rankAdjustmentsModule.deleteRankAdjustment.getType(), deleteRankAdjustment),
];
