import { connect } from 'react-redux';

import { logout } from '../../redux/modules/auth.module';

import Logout from './Logout.jsx';

export function mapStateToProps() {
  return {
  };
}

export const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
