import { createStore, applyMiddleware, compose } from 'redux';
import { reducer as form } from 'redux-form';
import createSagaMiddleware from 'redux-saga';

import reducer from './reducer';
import rootSaga from './saga/index.saga';
import * as states from './state/index.state';

import redirectOnLogin from './middleware/redirectOnLogin';

let devtool;
if (process.env.NODE_ENV !== 'test') {
  devtool = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
}

const sagaMiddleware = createSagaMiddleware();

const middleware = process.env.NODE_ENV === 'test' || !devtool
  ? applyMiddleware(
    sagaMiddleware,
    redirectOnLogin,
  )
  : compose(
    applyMiddleware(
      sagaMiddleware,
      redirectOnLogin,
    ),
    devtool,
  );

const combinedReducer = (state, action) => ({
  ...reducer(state, action),
  form: form(state.form, action),
});

const store = createStore(
  combinedReducer,
  {
    ...(process.env.NODE_ENV === 'test' ? states.test : states.initial),
    routing: {
      locationBeforeTransitions: null,
    },
  },
  middleware,
);

const saga = sagaMiddleware.run(rootSaga);
saga.toPromise().catch((error) => {
  throw error;
});

export default store;
