import { createAction } from 'redux-act';

import * as clicksReversals from '../../core/clicksReversals';

export const fetchClicks = createAction(
  'get all clicks',
);
export const fetchClicksSuccess = createAction(
  'get all clicks success',
  (allclicks) => allclicks
);
export const fetchClicksFail = createAction(
  'get all clicks fail',
  (error) => error
);

export const fetchClickIds = createAction(
  'get all clicks',
);
export const fetchClickIdsSuccess = createAction(
  'get all clicks success',
  (allclicks) => allclicks
);
export const fetchClickIdsFail = createAction(
  'get all clicks fail',
  (error) => error
);

export const processReversals = createAction(
  'process reversals',
);
export const processReversalsSuccess = createAction(
  'process reversals success',
  (allclicks) => allclicks
);
export const processReversalsFail = createAction(
  'process reversals fail',
  (error) => error
);

export const reducer = {
  [fetchClicks]: (state, payload) =>
    clicksReversals.fetchClicks(state, payload),
  [fetchClicksSuccess]: (state, payload) =>
    clicksReversals.fetchClicksSuccess(state, payload),
  [fetchClicksFail]: (state, payload) =>
    clicksReversals.fetchClicksFail(state, payload),

  [fetchClickIds]: (state, payload) =>
    clicksReversals.fetchClickIds(state, payload),
  [fetchClickIdsSuccess]: (state, payload) =>
    clicksReversals.fetchClickIdsSuccess(state, payload),
  [fetchClickIdsFail]: (state, payload) =>
    clicksReversals.fetchClickIdsFail(state, payload),

  [processReversals]: (state, payload) =>
    clicksReversals.processReversals(state, payload),
  [processReversalsSuccess]: (state, payload) =>
    clicksReversals.processReversalsSuccess(state, payload),
  [processReversalsFail]: (state, payload) =>
    clicksReversals.processReversalsFail(state, payload),
};
