import { apiRequest } from './_tools';

export function fetchClicksLeadsCount({ filter, statisticsIndex }) {
  const surveyProvider = encodeURIComponent(filter.surveyProvider);

  return apiRequest('/api/reports/clicks/clicksLeadsCount?' +
    `&dateFrom=${filter.date.from}` +
    `&dateTo=${filter.date.to}` +
    `&countryCode=${filter.countryCode}` +
    `&isMobile=${filter.isMobile}` +
    `&mobileOnly=${filter.mobileOnly}` +
    `&mobileResponsive=${filter.mobileResponsive}` +
    `&withAdditionalQuotas=${filter.withAdditionalQuotas}` +
    `&surveyVertical=${filter.surveyVertical}` +
    `&firstClick=${filter.firstClick}` +
    `&publisherId=${filter.publisherId}` +
    `&surveyRankType=${filter.surveyRankType}` +
    `&surveyAgeType=${filter.surveyAgeType}` +
    `&surveyLOIType=${filter.surveyLOIType}` +
    `&surveyCPI=${filter.surveyCPI}` +
    `&zeroRank=${filter.zeroRank}` +
    `&userAnswersType=${filter.userAnswersType}` +
    `&withUniqueUsers=${filter.withUniqueUsers}` +
    `&newUser=${filter.newUser}` +
    `&surveyProvider=${surveyProvider}` +
    `&statisticsIndex=${statisticsIndex}`)
  .catch(({ error }) => Promise.reject(error));
}

export function fetchClicksLeadsAllCountries({ filter, sort, statisticsIndex }) {
  const surveyProvider = encodeURIComponent(filter.surveyProvider);

  return apiRequest('/api/reports/clicks/clicksLeadsAllCountries?' +
    `dateFrom=${filter.date.from}` +
    `&dateTo=${filter.date.to}` +
    `&surveyProvider=${surveyProvider}` +
    `&isMobile=${filter.isMobile}` +
    `&mobileOnly=${filter.mobileOnly}` +
    `&mobileResponsive=${filter.mobileResponsive}` +
    `&withAdditionalQuotas=${filter.withAdditionalQuotas}` +
    `&surveyVertical=${filter.surveyVertical}` +
    `&firstClick=${filter.firstClick}` +
    `&publisherId=${filter.publisherId}` +
    `&surveyRankType=${filter.surveyRankType}` +
    `&surveyAgeType=${filter.surveyAgeType}` +
    `&withUniqueUsers=${filter.withUniqueUsers}` +
    `&surveyLOIType=${filter.surveyLOIType}` +
    `&surveyCPI=${filter.surveyCPI}` +
    `&zeroRank=${filter.zeroRank}` +
    `&userAnswersType=${filter.userAnswersType}` +
    `&newUser=${filter.newUser}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}` +
    `&statisticsIndex=${statisticsIndex}`)
  .catch(({ error }) => Promise.reject(error));
}

export function fetchClicksLeadsStatistics({ filter, statisticsIndex }) {
  const surveyProvider = encodeURIComponent(filter.surveyProvider);

  return apiRequest('/api/reports/clicks/clicksLeadsStatistics?' +
    `&dateFrom=${filter.date.from}` +
    `&dateTo=${filter.date.to}` +
    `&countryCode=${filter.countryCode}` +
    `&isMobile=${filter.isMobile}` +
    `&mobileOnly=${filter.mobileOnly}` +
    `&mobileResponsive=${filter.mobileResponsive}` +
    `&withAdditionalQuotas=${filter.withAdditionalQuotas}` +
    `&surveyVertical=${filter.surveyVertical}` +
    `&firstClick=${filter.firstClick}` +
    `&publisherId=${filter.publisherId}` +
    `&surveyRankType=${filter.surveyRankType}` +
    `&surveyAgeType=${filter.surveyAgeType}` +
    `&surveyLOIType=${filter.surveyLOIType}` +
    `&surveyCPI=${filter.surveyCPI}` +
    `&zeroRank=${filter.zeroRank}` +
    `&userAnswersType=${filter.userAnswersType}` +
    `&newUser=${filter.newUser}` +
    `&surveyProvider=${surveyProvider}` +
    `&intervalType=${filter.intervalType}` +
    `&statisticsIndex=${statisticsIndex}`)
  .catch(({ error }) => Promise.reject(error));
}

export function fetchStoppedClicksGroups({ filter }) {
  const surveyProvider = encodeURIComponent(filter.surveyProvider);

  return apiRequest('/api/reports/clicks/stoppedClicksGroups?' +
    `&dateFrom=${filter.date.from}` +
    `&dateTo=${filter.date.to}` +
    `&countryCode=${filter.countryCode}` +
    `&isMobile=${filter.isMobile}` +
    `&mobileOnly=${filter.mobileOnly}` +
    `&mobileResponsive=${filter.mobileResponsive}` +
    `&withAdditionalQuotas=${filter.withAdditionalQuotas}` +
    `&surveyVertical=${filter.surveyVertical}` +
    `&firstClick=${filter.firstClick}` +
    `&publisherId=${filter.publisherId}` +
    `&surveyRankType=${filter.surveyRankType}` +
    `&surveyLOIType=${filter.surveyLOIType}` +
    `&surveyCPI=${filter.surveyCPI}` +
    `&zeroRank=${filter.zeroRank}` +
    `&userAnswersType=${filter.userAnswersType}` +
    `&newUser=${filter.newUser}` +
    `&surveyAgeType=${filter.surveyAgeType}` +
    `&surveyProvider=${surveyProvider}`)
  .catch(({ error }) => Promise.reject(error));
}
