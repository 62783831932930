import { createAction } from 'redux-act';

import * as cronLogs from '../../core/cronLogs';

export const fetchCronLogs = createAction(
  'get all cron logs',
);
export const fetchCronLogsSuccess = createAction(
  'get all cron logs success',
  (allLogs) => allLogs
);
export const fetchCronLogsFail = createAction(
  'get all cron logs fail',
  (error) => error
);

export const reducer = {
  [fetchCronLogs]: (state, payload) =>
    cronLogs.fetchCronLogs(state, payload),
  [fetchCronLogsSuccess]: (state, payload) =>
    cronLogs.fetchCronLogsSuccess(state, payload),
  [fetchCronLogsFail]: (state, payload) =>
    cronLogs.fetchCronLogsFail(state, payload),
};
