export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'createDate',
    order: -1,
  },
  changeRankModal: {
    showModal: false,
    errorMessage: '',
    survey: {},
  },
  filter: {
    name: '',
    accountName: '',
    provider: 'federated',
    uniqueNumber: '',
    active: 'true',
    mobileResponsive: '',
    mobileOnly: '',
    surveyId: '',
    countryCode: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'createDate',
    order: -1,
  },
  changeRankModal: {
    showModal: false,
    errorMessage: '',
    survey: {},
  },
  filter: {
    name: '',
    accountName: '',
    provider: 'federated',
    uniqueNumber: '',
    active: 'true',
    mobileResponsive: '',
    mobileOnly: '',
    surveyId: '',
    countryCode: '',
  },
  isFetching: false,
  fetchingErrorMessage: '',
};
