export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'key',
    order: 1,
  },
  filter: {
  },
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  sort: {
    key: 'key',
    order: 1,
  },
  filter: {
  },
};
