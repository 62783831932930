import { apiRequest, putApiRequest } from './_tools';

export function fetchPublishers({ page, itemsPerPage, sort, filter }) {
  const name = encodeURIComponent(filter.name);
  return apiRequest(`/api/publishers?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&name=${name}` +
    `&email=${filter.email}` +
    `&apiKey=${filter.apiKey}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
    .catch(({ error }) => Promise.reject(error));
}

export function fetchAllPublishers() {
  return apiRequest('/api/publishers/list')
    .catch(({ error }) => Promise.reject(error));
}

export function fetchPublisherDetails(publisherId) {
  return apiRequest(`/api/publishers/${publisherId}`)
    .catch(({ error }) => Promise.reject(error));
}

export function updatePublisher({ publisherId, publisher }) {
  return putApiRequest(`/api/publishers/${publisherId}`, publisher);
}
