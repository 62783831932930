import _ from 'lodash';

import * as states from './index';

const t = _.reduce(states, (result, v, k) => {
  return {
    ...result,
    [k]: v.test,
  };
}, {});

export const test = t;

const i = _.reduce(states, (result, v, k) => {
  return {
    ...result,
    [k]: v.initial,
  };
}, {});

export const initial = i;
