import { apiRequest } from './_tools';

export function fetchClicks({ page, itemsPerPage, sort, filter }) {
  return apiRequest(`/api/clicksByPages?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&internalUserId=${filter.internalUserId}` +
    `&id=${filter.id}` +
    `&clickId=${filter.clickId}` +
    `&userId=${filter.userId}` +
    `&userCountryCode=${filter.userCountryCode}` +
    `&appId=${filter.appId}` +
    `&surveyId=${filter.surveyId}` +
    `&surveyUniqueNumber=${filter.surveyUniqueNumber}` +
    `&surveyProvider=${filter.surveyProvider}` +
    `&clickDateFrom=${filter.clickDate.from}` +
    `&clickDateTo=${filter.clickDate.to}` +
    `&ip=${filter.ip}` +
    `&isMobile=${filter.isMobile}` +
    `&profilerMatching=${filter.profilerMatching}` +
    `&leadDateFrom=${filter.leadDate.from}` +
    `&leadDateTo=${filter.leadDate.to}` +
    `&zeroRank=${filter.zeroRank}` +
    `&isWithInitialStatus=${filter.isWithInitialStatus}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
    .catch(({ error }) => Promise.reject(error));
}
