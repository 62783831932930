export function fetchSurveys(state, { page, sort, filter }) {
  return {
    ...state,
    surveys: {
      ...state.surveys,
      currentPage: page,
      sort: sort || state.surveys.sort,
      filter: filter || state.surveys.filter,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function fetchSurveysSuccess(state, surveys) {
  return {
    ...state,
    surveys: {
      ...state.surveys,
      numberOfPages: surveys.numberOfPages,
      count: surveys.count,
      items: surveys.items,
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function fetchSurveysFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    surveys: {
      ...state.surveys,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}

export function fetchSurveyResponseCodesStats(state, { surveyId, type }) {
  return {
    ...state,
    surveys: {
      ...state.surveys,
      statsModal: {
        ...state.surveys.statsModal,
        nowFetching: surveyId,
        type,
      },
    },
  };
}

export function fetchSurveyResponseCodesStatsSuccess(state, stats) {
  return {
    ...state,
    surveys: {
      ...state.surveys,
      statsModal: {
        ...state.surveys.statsModal,
        nowFetching: null,
        data: stats,
      },
    },
  };
}

export function fetchSurveyResponseCodesStatsFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    surveys: {
      ...state.surveys,
      statsModal: {
        ...state.surveys.statsModal,
        nowFetching: null,
        fetchingErrorMessage: errorMessage,
      },
    },
  };
}

export function openSurveyStatsModal(state) {
  return {
    ...state,
    surveys: {
      ...state.surveys,
      statsModal: {
        ...state.surveys.statsModal,
        showModal: true,
      },
    },
  };
}

export function closeSurveyStatsModal(state) {
  return {
    ...state,
    surveys: {
      ...state.surveys,
      statsModal: {
        ...state.surveys.statsModal,
        showModal: false,
      },
    },
  };
}

export function changeSurveyPreferredValue(state) {
  return {
    ...state,
    surveys: {
      ...state.surveys,
      fetchingErrorMessage: '',
      isFetching: true,
    },
  };
}

export function changeSurveyPreferredValueSuccess(state, { surveyId, newPreferredValue }) {
  const surveyIndex = state.surveys.items.findIndex(x => x._id === surveyId);
  const survey = state.surveys.items.find(x => x._id === surveyId);
  if (!survey) {
    return state;
  }
  return {
    ...state,
    surveys: {
      ...state.surveys,
      items: [
        ...state.surveys.items.slice(0, surveyIndex),
        {
          ...survey,
          isPreferred: newPreferredValue,
        },
        ...state.surveys.items.slice(surveyIndex + 1),
      ],
      fetchingErrorMessage: '',
      isFetching: false,
    },
  };
}

export function changeSurveyPreferredValueFail(state, error) {
  const errorMessage =
    error.message ? error.message : 'Request failed. Open the browser console for details.';
  return {
    ...state,
    surveys: {
      ...state.surveys,
      isFetching: false,
      fetchingErrorMessage: errorMessage,
    },
  };
}
