import React from 'react';
import { Navigate } from 'react-router-dom';

import Root from './Root.auth.jsx';
import LoginContainer from '../auth/Login.container.js';
import LogoutContainer from '../auth/Logout.container.js';
import NotFound from '../NotFound.jsx';

import * as routes from './routes';

export default [{
    path: '/auth',
    element: <Navigate to='/auth/login' replace={true} />,
  }, {
    path: routes.auth.prefix,
    element: <Root />,
    children: [{
      path: routes.auth.login,
      element: <LoginContainer />,
    }, {
      path: routes.auth.logout,
      element: <LogoutContainer />,
    }],
  }, {
    path: '*',
    element: <NotFound />,
  },
];
