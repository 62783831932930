import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as extraAnswersReportsModule from '../modules/extraAnswersReports.module';
import * as Api from '../../core/api';
import { getFilter } from './selectors/extraAnswersReports';

function* fetchExtraAnswersReports({ payload: { page, itemsPerPage } }) {
  const filter = yield select(getFilter);
  const { response, error } =
    yield call(Api.extraAnswersReports.fetchExtraAnswersReports, { page, itemsPerPage, filter });
  if (response) {
    yield put(extraAnswersReportsModule.fetchExtraAnswersReportsSuccess(response));
  } else {
    yield put(extraAnswersReportsModule.fetchExtraAnswersReportsFail(error));
  }
}

export default [
  takeEvery(
    extraAnswersReportsModule.fetchExtraAnswersReports.getType(),
    fetchExtraAnswersReports
  ),
];
