import { apiRequest, postApiRequest } from './_tools';

export function fetchManagers({ page, itemsPerPage, sort }) {
  return apiRequest(`/api/managers?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
    .catch(({ error }) => Promise.reject(error));
}

export function addManager(manager) {
  return postApiRequest('/api/managers', manager)
    .catch(({ error }) => Promise.reject(error));
}
