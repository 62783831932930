import { createAction } from 'redux-act';

import * as surveyQualificationsLogs from '../../core/surveyQualificationsLogs';

export const fetchSurveyQualificationsLogs = createAction(
  'get all api survey qualifications logs',
);
export const fetchSurveyQualificationsLogsSuccess = createAction(
  'get all api survey qualifications success',
  (allLogs) => allLogs
);
export const fetchSurveyQualificationsLogsFail = createAction(
  'get all api survey qualifications fail',
  (error) => error
);

export const openSurveyQualificationsModal = createAction(
  'open qualifications modal'
);
export const closeSurveyQualificationsModal = createAction(
  'close qualifications modal'
);


export const reducer = {
  [fetchSurveyQualificationsLogs]: (state, payload) =>
    surveyQualificationsLogs.fetchSurveyQualificationsLogs(state, payload),
  [fetchSurveyQualificationsLogsSuccess]: (state, payload) =>
    surveyQualificationsLogs.fetchSurveyQualificationsLogsSuccess(state, payload),
  [fetchSurveyQualificationsLogsFail]: (state, payload) =>
    surveyQualificationsLogs.fetchSurveyQualificationsLogsFail(state, payload),

  [openSurveyQualificationsModal]: (state, payload) =>
    surveyQualificationsLogs.openSurveyQualificationsModal(state, payload),
  [closeSurveyQualificationsModal]: (state, payload) =>
    surveyQualificationsLogs.closeSurveyQualificationsModal(state, payload),
};
