import { apiRequest } from './_tools';

export function fetchCronLogs({ page, itemsPerPage, sort, filter }) {
  return apiRequest(`/api/cronLogs?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&name=${filter.name}` +
    `&dateFrom=${filter.startDate.from}` +
    `&dateTo=${filter.startDate.to}` +
    `&isWithErrors=${filter.isWithErrors}` +
    `&isWithGlobalError=${filter.isWithGlobalError}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
  .catch(({ error }) => Promise.reject(error));
}
