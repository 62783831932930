export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  countryLanguageCode: 'ENG-US',
  userId: '',
  isFetching: false,
  fetchingErrorMessage: '',
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  countryLanguageCode: 'ENG-US',
  userId: '',
  isFetching: false,
  fetchingErrorMessage: '',
};
