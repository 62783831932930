import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as globalSettingsModule from '../modules/globalSettings.module';
import * as Api from '../../core/api';
import { getDefaultActiveRankTypes, getProviderChanges, getFeatureFlags } from './selectors/globalSettings';

function* fetchGlobalSettings() {
  const { response, error } = yield call(Api.globalSettings.fetchGlobalSettings);

  if (response) {
    yield put(globalSettingsModule.fetchGlobalSettingsSuccess(response));
  } else {
    yield put(globalSettingsModule.fetchGlobalSettingsFail(error));
  }
}

function* changeProviderStatus() {
  const { provider, status, activeCountries } = yield select(getProviderChanges);
  const { response, error } = yield call(Api.globalSettings.changeProviderStatus, { provider, status, activeCountries });

  if (response) {
    yield put(globalSettingsModule.changeProviderStatusSuccess({
      ...response,
      activeCountries,
    }));
  } else {
    yield put(globalSettingsModule.changeProviderStatusFail(error));
  }
}

function* changeDefaultActiveRankTypes() {
  const defaultActiveRankTypes = yield select(getDefaultActiveRankTypes);

  const { response, error } = yield call(
    Api.globalSettings.changeDefaultActiveRankTypes,
    { defaultActiveRankTypes },
  );

  if (response) {
    yield put(globalSettingsModule.changeDefaultActiveRankTypesSuccess(response));
  } else {
    yield put(globalSettingsModule.changeDefaultActiveRankTypesFail(error));
  }
}

function* changeFeatureStatus() {
  const featureFlags = yield select(getFeatureFlags);
  const { response, error } = yield call(Api.globalSettings.updateFeatureFlags, featureFlags);

  if (response) {
    yield put(globalSettingsModule.changeFeatureStatusSuccess({
      ...response,
    }));
  } else {
    yield put(globalSettingsModule.changeFeatureStatusFail(error));
  }
}

export default [
  takeEvery(globalSettingsModule.fetchGlobalSettings.getType(), fetchGlobalSettings),
  takeEvery(globalSettingsModule.changeProviderStatus.getType(), changeProviderStatus),
  takeEvery(
    globalSettingsModule.changeDefaultActiveRankTypes.getType(),
    changeDefaultActiveRankTypes,
  ),
  takeEvery(globalSettingsModule.changeFeatureStatus.getType(), changeFeatureStatus),
];
