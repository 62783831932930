import moment from 'moment-timezone';

const state = {
  clicksCount: null,
  leadsCount: null,
  stoppedClicksCount: null,
  totalNET: null,
  totalRevenue: null,
  filter: {
    date1: {
      from: moment.tz('America/Los_Angeles').startOf('day').format('MM/DD/YYYY'),
      to: moment.tz('America/Los_Angeles').startOf('day').format('MM/DD/YYYY'),
    },
    date2: {
      from: moment.tz('America/Los_Angeles').startOf('day').format('MM/DD/YYYY'),
      to: moment.tz('America/Los_Angeles').startOf('day').format('MM/DD/YYYY'),
    },
    surveyProvider: '',
    countryCode: '',
    isMobile: '',
    mobileOnly: '',
    mobileResponsive: '',
    publisherId: '',
    surveyRankType1: '',
    surveyRankType2: '',
    withAdditionalQuotas1: '',
    withAdditionalQuotas2: '',
    surveyVertical1: '',
    surveyVertical2: '',
    firstClick1: '',
    firstClick2: '',
    surveyAgeType1: '',
    surveyAgeType2: '',
    surveyLOIType1: '',
    surveyLOIType2: '',
    surveyCPI1: '',
    surveyCPI2: '',
    userAnswersType1: '',
    userAnswersType2: '',
    zeroRank1: '',
    zeroRank2: '',
    newUser1: '',
    newUser2: '',
    intervalType1: 'hour',
    intervalType2: 'hour',
  },
  filter1: { filter: {} },
  filter2: { filter: {} },
  statistics: {},
  stoppedClicksGroups: [],
  allCountries: {
    items: [],
    sort: {
      key: 'clicksCount',
      order: -1,
    },
  },
  isFetching: false,
  fetchingErrorMessages: [],
  activeFetchingCounter: 0,
};

export const test = state;

export const initial = state;
