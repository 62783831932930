import { createAction } from 'redux-act';

import * as countryLanguages from '../../core/countryLanguages';

export const fetchAllCountryLanguages = createAction(
  'get all country languages',
);
export const fetchAllCountryLanguagesSuccess = createAction(
  'get all country languages success',
  (allcountryLanguages) => allcountryLanguages
);
export const fetchAllCountryLanguagesFail = createAction(
  'get all country languages fail',
  (error) => error
);

export const reducer = {
  [fetchAllCountryLanguagesSuccess]: (state, payload) =>
    countryLanguages.fetchAllCountryLanguagesSuccess(state, payload),
};
