export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  list: [],
  sort: {
    key: 'name',
    order: 1,
  },
  filter: {
    name: '',
    email: '',
    apiKey: '',
  },
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  items: [],
  list: [],
  sort: {
    key: 'name',
    order: 1,
  },
  filter: {
    name: '',
    email: '',
    apiKey: '',
  },
};
