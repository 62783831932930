import { apiRequest, putApiRequest } from './_tools';

export function fetchSurveys({ page, itemsPerPage, sort, filter }) {
  const name = encodeURIComponent(filter.name);
  const accountName = encodeURIComponent(filter.accountName);
  return apiRequest(`/api/surveysByPages/ranking?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&name=${name}` +
    `&accountName=${accountName}` +
    `&provider=${filter.provider}` +
    `&uniqueNumber=${filter.uniqueNumber}` +
    `&active=${filter.active}` +
    `&mobileResponsive=${filter.mobileResponsive}` +
    `&mobileOnly=${filter.mobileOnly}` +
    `&surveyId=${filter.surveyId}` +
    `&countryCode=${filter.countryCode}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
    .catch(({ error }) => Promise.reject(error));
}

export function changeSurveyRank({ surveyId, rankValue }) {
  return putApiRequest(`/api/surveys/${surveyId}/rank`, { rankValue });
}
