import { apiRequest, postApiRequest, putApiRequest, deleteApiRequest } from './_tools';

export function fetchRankAdjustments({ page, itemsPerPage, sort }) {
  return apiRequest(`/api/survey/rankAdjustments?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
  .catch(({ error }) => Promise.reject(error));
}

export function addRankAdjustment(rankAdjustment) {
  return postApiRequest('/api/survey/rankAdjustments', rankAdjustment)
    .catch(({ error }) => Promise.reject(error));
}

export function updateRankAdjustment({ rankAdjustmentId, rankAdjustment }) {
  return putApiRequest(`/api/survey/rankAdjustments/${rankAdjustmentId}`, rankAdjustment);
}

export function deleteRankAdjustment({ rankAdjustmentId }) {
  return deleteApiRequest(`/api/survey/rankAdjustments/${rankAdjustmentId}`);
}
