export const test = {
  currentPage: 1,
  itemsPerPage: 10,
  filter: {
    countryCode: '',
    date: {
      from: '',
      to: '',
    },
    appId: '',
  },
  items: [],
  itemsByCountry: [],
  isFetching: false,
  fetchingErrorMessages: [],
  activeFetchingCounter: 0,
};

export const initial = {
  currentPage: 1,
  itemsPerPage: 10,
  filter: {
    countryCode: '',
    date: {
      from: '',
      to: '',
    },
    appId: '',
  },
  items: [],
  itemsByCountry: [],
  isFetching: false,
  fetchingErrorMessages: [],
  activeFetchingCounter: 0,
};
