import { takeEvery, put, call, select } from 'redux-saga/effects';

import * as mappingsModule from '../modules/mappings.module';
import * as Api from '../../core/api';

import { getFilter, getProvider } from './selectors/mappings';

function* fetchMappings({ payload: { page, itemsPerPage } }) {
  const filter = yield select(getFilter);
  const provider = yield select(getProvider);

  const { response, error } =
    yield call(Api.mappings.fetchMappings, { provider, page, itemsPerPage, filter });

  if (response) {
    yield put(mappingsModule.fetchMappingsSuccess(response));
  } else {
    yield put(mappingsModule.fetchMappingsFail(error));
  }
}

function* addAnotherQuestion({ payload: { provider, questionId, country } }) {
  const { response, error } =
    yield call(Api.mappings.addAnotherQuestion, { provider, questionId, country });

  if (response) {
    yield put(mappingsModule.addAnotherQuestionSuccess(response));
  } else {
    yield put(mappingsModule.addAnotherQuestionFail(error));
  }
}

function* fetchMappingsDetails({ payload: { provider, country, questionId, index } }) {
  const { response, error } =
    yield call(Api.mappings.fetchMappingsDetails, { provider, country, questionId, index });

  if (response) {
    yield put(mappingsModule.fetchMappingsDetailsSuccess(response));
  } else {
    yield put(mappingsModule.fetchMappingsDetailsFail(error));
  }
}

function* fetchQuestions({ payload: { countryLanguageId } }) {
  const { response, error } =
    yield call(Api.mappings.fetchQuestions, { countryLanguageId });

  if (response) {
    yield put(mappingsModule.fetchQuestionsSuccess(response));
  } else {
    yield put(mappingsModule.fetchQuestionsFail(error));
  }
}

function* changeQuestionText({ payload: { countryLanguageCode, questionId, text } }) {
  const { response, error } =
    yield call(Api.mappings.changeQuestionText, { countryLanguageCode, questionId, text });

  if (response) {
    yield put(mappingsModule.changeQuestionTextSuccess({ countryLanguageCode, questionId, text }));
  } else {
    yield put(mappingsModule.changeQuestionTextFail(error));
  }
}

function* saveMappings({ payload: { provider, country, questionId, index, mappings } }) {
  const { response, error } =
    yield call(Api.mappings.saveMappings, { provider, country, questionId, index, mappings });

  if (response) {
    yield put(mappingsModule.saveMappingsSuccess(response));
  } else {
    yield put(mappingsModule.saveMappingsFail(error));
  }
}

function* removeQuestionMapping({ payload: { questionId, provider, country, index } }) {
  const { response, error } =
    yield call(Api.mappings.removeQuestionMapping, { questionId, provider, country, index });

  if (response) {
    yield put(mappingsModule.removeQuestionMappingSuccess());
  } else {
    yield put(mappingsModule.removeQuestionMappingFail(error));
  }
}

function* changeMappingStatus({ payload: { questionId, provider, country, index } }) {
  const { response, error } =
    yield call(Api.mappings.changeMappingStatus, { questionId, provider, country, index });

  if (response) {
    yield put(mappingsModule.changeMappingStatusSuccess());
  } else {
    yield put(mappingsModule.changeMappingStatusFail(error));
  }
}

export default [
  takeEvery(mappingsModule.fetchMappings.getType(), fetchMappings),
  takeEvery(mappingsModule.addAnotherQuestion.getType(), addAnotherQuestion),
  takeEvery(mappingsModule.fetchMappingsDetails.getType(), fetchMappingsDetails),
  takeEvery(mappingsModule.fetchQuestions.getType(), fetchQuestions),
  takeEvery(mappingsModule.changeQuestionText.getType(), changeQuestionText),
  takeEvery(mappingsModule.saveMappings.getType(), saveMappings),
  takeEvery(mappingsModule.removeQuestionMapping.getType(), removeQuestionMapping),
  takeEvery(mappingsModule.changeMappingStatus.getType(), changeMappingStatus),
];

