import { apiRequest } from './_tools';

export function fetchCities({ page, itemsPerPage, sort, filter }) {
  const city = encodeURIComponent(filter.city);

  return apiRequest(`/api/citiesTable?page=${page}` +
    `&itemsPerPage=${itemsPerPage}` +
    `&city=${city}` +
    `&state=${filter.state}` +
    `&metroCode=${filter.metroCode}` +
    `&sortKey=${sort.key}` +
    `&order=${sort.order}`)
  .catch(({ error }) => Promise.reject(error));
}
