import React from 'react';


function NotFound() {
  return (
    <div>
      404 Page Not Found
    </div>
  );
}

export default NotFound;
